<template>
  <nav>
  </nav>
  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import customJS from '@/assets/js/custom.js';
import { onMounted } from 'vue';

export default defineComponent({
  name: 'App',
  setup() {
    onMounted(() => {
      customJS();
    });
  }
});
</script>

