<template>
    <div class="modal fade big-pop" id="appointmentPop" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="pop-row">
                    <div class="pop-row-left">
                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active"
                                    :style="{ 'background-image': `url(${require('@/assets/img/dummy/about-section-img.png')})` }">
                                    <!-- style="background-image: url(../assets/img/dummy/about-section-img.png);"> -->

                                </div>
                                <div class="carousel-item"
                                    :style="{ 'background-image': `url(${require('@/assets/img/dummy/pop-slider-img.jpg')})` }">
                                    <!-- style="background-image: url(../assets/img/dummy/pop-slider-img.jpg);"> -->

                                </div>
                                <div class="carousel-item"
                                    :style="{ 'background-image': `url(${require('@/assets/img/dummy/about-section-img.png')})` }">
                                    <!-- style="background-image: url(../assets/img/dummy/about-section-img.png);"> -->
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                data-bs-slide="prev">
                                <img src="../assets/img/yellow-arrow-left.svg" alt="" />
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                data-bs-slide="next">
                                <img src="../assets/img/yellow-arrow-right.svg" alt="" />
                            </a>
                        </div>
                    </div>
                    <div class="pop-row-right">
                        <button class="close-pop-btn" data-bs-dismiss="modal" aria-label="Close"
                            @click="resetForm()"></button>
                        <h2>{{ constants.BOOK_AN_APPOINTMENT_TEXT }}</h2>
                        <div class="appointment-form">
                            <form @submit.prevent="submitAppointmentForm">
                                <div class="input-field" :class="{ error: titleError && !Name }">
                                    <input type="text" placeholder="Enter Name" v-model="Name" />
                                    <span class="error-text" v-if="titleError && !Name">{{
                                        constants.THIS_FIELD_IS_REQUIRED }}</span>
                                </div>
                                <div class="input-field"
                                    :class="{ error: (titleError && !Email) || (emailError.length) }">
                                    <input type="text" placeholder="Enter Email" v-model="Email"
                                        @input="validateEmail(nextClicked)" />
                                    <span class="error-text" v-if="titleError && !Email">{{
                                        constants.THIS_FIELD_IS_REQUIRED }}</span>
                                    <span class="error-text" v-if="!(titleError && !Email)">{{ emailError }}</span>
                                </div>
                                <div class="input-field" :class="{ error: titleError && !number }">
                                    <input type="text" placeholder="Enter Contact Number" v-model="number"
                                        @keypress="preventNonNumeric($event)" />
                                    <span class="error-text" v-if="titleError && !number">{{
                                        constants.THIS_FIELD_IS_REQUIRED
                                    }}</span>
                                </div>
                                <div class="input-field">
                                    <div class="select-date-item">
                                        <label class="label-flex">{{ constants.SELECT_UP_TO_THREE_DATES_LABEL }}
                                            <span>{{ constants.PREFERRED_LABEL }}</span></label>
                                        <div class="select-date-item-row">
                                            <div class="select-date-calender" :class="{ error: titleError && !Date1 }">
                                                <datepicker :disabled-dates="disabledDatesObject" v-model="Date1"
                                                    id="datepicker1" class="date-icon" placeholder="Enter Date">
                                                </datepicker>

                                            </div>
                                            <div class="select-time-type"
                                                :class="{ error: titleError && !selectedSlot1 }">
                                                <button type="button" @click="selectSlot1('AM')"
                                                    :class="{ active: selectedSlot1 === 'AM' }">{{ constants.AM_BUTTON
                                                    }}</button>
                                            </div>
                                            <div class="select-time-type"
                                                :class="{ error: titleError && !selectedSlot1 }">
                                                <button type="button" @click="selectSlot1('PM')"
                                                    :class="{ active: selectedSlot1 === 'PM' }">{{ constants.PM_BUTTON
                                                    }}</button>
                                            </div>

                                        </div>
                                    </div>
                                    <span class="error-text" v-if="titleError && !selectedSlot1">{{
                                        constants.THIS_FIELD_IS_REQUIRED
                                    }}</span>
                                    <div class="select-date-item">
                                        <label class="label-flex"> <span>{{ constants.ALTERNATE_LABEL }}
                                                {{ constants.ONE }}</span></label>
                                        <div class="select-date-item-row">
                                            <div class="select-date-calender">
                                                <datepicker :disabled-dates="disabledDatesObject" v-model="Date2"
                                                    id="datepicker1" class="date-icon" placeholder="Enter Date">
                                                </datepicker>
                                            </div>
                                            <div class="select-time-type">
                                                <button type="button" @click="selectSlot2('AM')"
                                                    :class="{ active: selectedSlot2 === 'AM' }">{{ constants.AM_BUTTON
                                                    }}</button>
                                            </div>
                                            <div class="select-time-type">
                                                <button type="button" @click="selectSlot2('PM')"
                                                    :class="{ active: selectedSlot2 === 'PM' }">{{ constants.PM_BUTTON
                                                    }}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="select-date-item">
                                        <label class="label-flex"> <span>{{ constants.ALTERNATE_LABEL }}
                                                {{ constants.TWO }}</span></label>
                                        <div class="select-date-item-row">
                                            <div class="select-date-calender">
                                                <datepicker :disabled-dates="disabledDatesObject" v-model="Date3"
                                                    id="datepicker1" class="date-icon" placeholder="Enter Date">
                                                </datepicker>
                                            </div>
                                            <div class="select-time-type">
                                                <button type="button" @click="selectSlot3('AM')"
                                                    :class="{ active: selectedSlot3 === 'AM' }">{{ constants.AM_BUTTON
                                                    }}</button>
                                            </div>
                                            <div class="select-time-type">
                                                <button type="button" @click="selectSlot3('PM')"
                                                    :class="{ active: selectedSlot3 === 'PM' }">{{ constants.PM_BUTTON
                                                    }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-field">
                                    <label class="optional-label"><span>{{ constants.OPTIONAL_LABEL }}</span></label>
                                    <textarea placeholder="Enter the additional comments" v-model="Comments"></textarea>
                                </div>
                                <div class="input-field-submit">
                                    <div class="button-center">
                                        <button class="secondary-outline-btn" data-bs-dismiss="modal"
                                            @click.prevent="resetForm">{{ constants.CANCEL_BUTTON }} </button>
                                        <button type="submit" class="primary-outline-btn">{{ constants.SUBMIT_BUTTON
                                            }}</button>
                                        <button type="button" class="dummy-btn" data-bs-dismiss="modal"
                                            ref="dummyButton" style="display: none;"></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script lang="ts">
import axios from 'axios';
import { ref, onMounted, defineComponent } from 'vue';
import constants from '@/utils/en-US-User.json';
import $ from 'jquery';
import 'bootstrap-datepicker';
import { easingOptions } from 'aos';
import { API_BASE_URL } from '@/config/api.config';
import Datepicker from 'vue3-datepicker';
import moment from 'moment';

export default defineComponent({

    data() {
        return {
            constants: constants,
            Name: '',
            Email: '',
            number: '',
            Date1: null,
            Slot1: '',
            selectedSlot1: '',
            Date2: null,
            Slot2: '',
            selectedSlot2: '',
            Date3: null,
            Slot3: '',
            selectedSlot3: '',
            Comments: '',
            titleError: false,
            emailError: '',
            nextClicked: false
        };
    },
    components: {
        Datepicker
    },
    computed: {
        disabledDatesObject() {
            return {
                predicate: (date: Date) => {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    return date < today;
                }
            }
        }
    },
    methods: {
        preventNonNumeric(event: any) {
            const keyCode = event.keyCode ? event.keyCode : event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 43 && keyCode !== 45 && keyCode !== 101) { // 43 is for '+', 45 is for '-', 101 is for 'e'
                event.preventDefault();
            }
        },
        validateEmail(nextClicked: boolean) {
            if (nextClicked) {
                if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.Email))) {
                    this.emailError = constants.VALID_EMAIL;
                } else {
                    this.emailError = '';
                }
            }
        },
        selectSlot1(value: string) {
            if (this.selectedSlot1 === value) {
                this.selectedSlot1 = '';
                this.Slot1 = '';
            } else {
                this.selectedSlot1 = value;
                this.Slot1 = value;
            }
        },
        selectSlot2(value: string) {
            if (this.selectedSlot2 === value) {
                this.selectedSlot2 = '';
                this.Slot2 = '';
            } else {
                this.selectedSlot2 = value;
                this.Slot2 = value;
            }
        },
        selectSlot3(value: string) {
            if (this.selectedSlot3 === value) {
                this.selectedSlot3 = '';
                this.Slot3 = '';
            } else {
                this.selectedSlot3 = value;
                this.Slot3 = value;
            }
        },
        async submitAppointmentForm(event: Event) {
            try {
                this.nextClicked = true;
                this.validateEmail(this.nextClicked);
                const formattedDate1 = this.Date1 ? moment(this.Date1).format('YYYY-MM-DD') : null;
                const formattedDate2 = this.Date2 ? moment(this.Date2).format('YYYY-MM-DD') : null;
                const formattedDate3 = this.Date3 ? moment(this.Date3).format('YYYY-MM-DD') : null;
                if (!this.Name || !this.Email || !this.number || !formattedDate1 || !this.Slot1) {
                    this.titleError = true;
                    return;
                } else {
                    this.titleError = false;
                }
                await axios.post(`${API_BASE_URL}/appointments/`, {
                    Name: this.Name,
                    Email: this.Email,
                    Number: this.number,
                    Date1: formattedDate1,
                    Slot1: this.Slot1,
                    Date2: formattedDate2,
                    Slot2: this.Slot2,
                    Date3: formattedDate3,
                    Slot3: this.Slot3,
                    Comments: this.Comments,
                });
                // event.preventDefault();
                this.resetForm();
                this.nextClicked = false;
                (this.$refs.dummyButton as HTMLElement).click();
                this.$store.commit('showPopup')
                setTimeout(() => {
                    this.$store.commit('hidePopup')
                }, 10000)
            } catch (error) {
                console.error(error);
            }
        },
        async resetForm() {
            this.Name = '';
            this.Email = '';
            this.number = '';
            this.Date1 = null;
            this.Slot1 = '';
            this.selectedSlot1 = '';
            this.Date2 = null;
            this.Slot2 = '';
            this.selectedSlot2 = '';
            this.Date3 = null;
            this.Slot3 = '';
            this.selectedSlot3 = '';
            this.Comments = '';
            this.titleError = false;
            this.emailError = '';
            this.nextClicked = false;

        }
    },

});
</script>