<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <div class="heading-buttons">
                            <h1>
                                {{ constants.BOOKING_APPOINTMENTS_TEXT }}
                                <!-- <script>display("BOOKING_APPOINTMENTS_TEXT");</script> -->
                            </h1>
                            <div class="heading-buttons-set">
                                <button class="delete-item txt-btn"
                                    :class="{ 'btn-disabled': !isAnyAppointmentSelected }"
                                    :disabled="!isAnyAppointmentSelected" @click="deleteSelectedAppointments()">

                                    {{ constants.DELETE_BUTTON }}

                                </button>
                            </div>
                        </div>
                        <div class=" appointments-table" data-simplebar>
                            <div class="table-view appointments">
                                <div class="table-view-top">
                                    <div class="table-row">
                                        <div class="checkbox-item pd-2">
                                            <div class="checkbox">
                                                <input name="cssCheckbox" id="selectAll" type="checkbox"
                                                    class="css-checkbox" @change="toggleSelectAll"
                                                    :checked="selectedAppointments.length === appointments.length && appointments.length > 0">
                                                <label for="selectAll"></label>
                                            </div>
                                        </div>
                                        <div class="appointments-name-item pd-1">

                                            {{ constants.NAME_LABEL }}

                                        </div>
                                        <div class="appointments-email-item pd-1">

                                            {{ constants.EMAIL_LABEL }}

                                        </div>
                                        <div class="appointments-phone-item pd-1">

                                            {{ constants.CONACT_NO_LABEL }}

                                        </div>
                                        <div class="appointments-date-item pd-1">

                                            {{ constants.SELECTED_DATE_LABEL }}

                                        </div>
                                        <div class="appointments-comments-item pd-1">

                                            {{ constants.COMMENTS_LABEL }}


                                        </div>
                                        <div class="record-generated-item pd-1">
                                            {{ constants.RECORD_GENERATED_LABEL }}
                                        </div>
                                    </div>
                                </div>
                                <div class="table-view-btm table-scroll">

                                    <div class="table-row" v-for="appointment in appointments" :key="appointment.id">
                                        <div class="checkbox-item pd-2">
                                            <div class="checkbox">
                                                <input name="cssCheckbox" :id="'demo_opt_' + appointment.id"
                                                    type="checkbox" class="css-checkbox"
                                                    @change="toggleAppointmentSelection(appointment.id)"
                                                    :checked="selectedAppointments.includes(appointment.id)">
                                                <label :for="`demo_opt_${appointment.id}`"></label>
                                            </div>
                                        </div>
                                        <div class="appointments-name-item pd-1" :title="appointment.Name">{{
                                            appointment.Name }}</div>
                                        <div class="appointments-email-item pd-1" :title="appointment.Email">{{
                                            appointment.Email }}</div>
                                        <div class="appointments-phone-item pd-1">{{ appointment.Number }}</div>
                                        <div class="appointments-date-item pd-1 full-des time-slot"
                                            v-if="appointment.Date1">
                                            {{ formatDate(appointment.Date1) }}
                                            <div class="full-des-box">
                                                <div class="full-des-box-in">
                                                    <div class="full-des-icon"></div>
                                                    <div class="full-des-content">
                                                        <div class="full-des-content-in">
                                                            <ul class="time-slot-in">
                                                                <li v-if="appointment.Date1">{{
                                                                    formatDate(appointment.Date1) }}
                                                                    {{ appointment.Slot1 }}
                                                                    <strong>{{constants.PREFERRED_LABEL}}</strong>
                                                                </li>
                                                                <li v-if="appointment.Date2">{{
                                                                    formatDate(appointment.Date2) }}
                                                                    {{ appointment.Slot2 }} <strong>{{constants.ALTERNATE_LABEL}}
                                                                        {{constants.ONE}}</strong></li>
                                                                <li v-if="appointment.Date3">{{
                                                                    formatDate(appointment.Date3) }}
                                                                    {{ appointment.Slot3 }} <strong>{{constants.ALTERNATE_LABEL}}
                                                                        {{constants.TWO}}</strong></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="appointments-comments-item pd-1 full-des">
                                            <span>{{ appointment.Comments }}</span>
                                            <div class="full-des-box">
                                                <div class="full-des-box-in">
                                                    <div class="full-des-icon"></div>
                                                    <div class="full-des-content">
                                                        <div class="full-des-content-in">
                                                            <p>{{ appointment.Comments }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="record-generated-item pd-1">
                                            {{ formatDateAppointment(appointment.Created_at) }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="pagination-box">
                            <div class="pagination-box-in">
                                <ul>
                                    <li v-if="currentPage !== 1" class="pre-pagination"
                                        @click="changePage(currentPage - 1)">
                                        <a href="#"></a>
                                    </li>
                                    <li v-for="page in visiblePages" :key="page" class="num-pagination"
                                        @click="changePage(page)" :class="{ active: currentPage === page }">
                                        <a href="#">{{ page }}</a>
                                    </li>
                                    <li v-if="currentPage !== totalPages" class="next-pagination"
                                        @click="changePage(currentPage + 1)">
                                        <a href="#"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">

                {{ constants.COPYRIGHT_TEXT }}

            </div>
        </footer>
    </section>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import constants from '@/utils/en-US-Admin.json';
import HeaderComponent from "@/components/HeaderComponent.vue";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import axios from 'axios';
import moment from 'moment';


interface Appointment {
    id: number;
    Name: string;
    Email: string;
    Number: string;
    Date1: string;
    Slot1: string;
    Date2: string;
    Slot2: string;
    Date3: string;
    Slot3: string;
    Comments: string;
    Created_at: string;
    Updated_at: string;
}

export default defineComponent({
    name: 'BookingAppointment',
    setup() {
        const activeIcon = ref('adminBooking');
        const selectedAppointments = ref([] as number[]);
        const isAnyAppointmentSelected = computed(() => selectedAppointments.value.length > 0);

        return {
            activeIcon,
            selectedAppointments,
            isAnyAppointmentSelected
        };
    },
    components: {
        HeaderComponent,
        LeftSidebarComponent
    },
    data() {
        return {
            constants,
            appointments: [] as Appointment[],
            currentPage: 1,
            totalPages: 1,
            selectedAppointments: [] as number[],
        };
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    computed: {
        visiblePages() {
            const maxVisiblePages = 5; // Maximum number of page numbers to show
            const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
            let start = Math.max(this.currentPage - halfMaxVisiblePages, 1);
            let end = start + maxVisiblePages - 1;

            if (end > this.totalPages) {
                end = this.totalPages;
                start = Math.max(end - maxVisiblePages + 1, 1);
            }

            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },
    },
    created() {
        this.fetchAppointments();
    },
    methods: {
        formatDate(dateString: any) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${day}/${month}/${year}`;
        },
        async fetchAppointments() {
            try {
                const limit = 10;
                const skip = (this.currentPage - 1) * limit;
                // const response = await axios.get(`${API_BASE_URL}/appointments/`, { limit, skip });
                const response = await axios.post(`${API_BASE_URL}/appointments/`, { limit, skip });
                this.appointments = response.data.data;
                this.totalPages = Math.ceil(response.data.total_count / limit);
            } catch (error) {
                console.log(error);

            }
        },
        formatDateAppointment(dateStr: string) {
            let date = moment(dateStr);
            return date.format('DD/MM/YYYY hh:mm a');
        },
        changePage(page: number) {
            if (page > 0 && page <= this.totalPages) {
                this.currentPage = page;
                this.fetchAppointments();
            }
        },
        toggleAppointmentSelection(appointmentsId: number) {
            if (this.selectedAppointments.includes(appointmentsId)) {
                this.selectedAppointments = this.selectedAppointments.filter(id => id !== appointmentsId);
            } else {
                this.selectedAppointments.push(appointmentsId);
            }
        },
        toggleSelectAll() {
            if (this.selectedAppointments.length === this.appointments.length) {
                this.selectedAppointments = [];
            } else {
                this.selectedAppointments = this.appointments.map(appointments => appointments.id);
            }
        },
        async deleteSelectedAppointments() {
            try {
                await axios.put(`${API_BASE_URL}/appointments/update/`, { appointmentIds: this.selectedAppointments });
                this.appointments = this.appointments.filter(appointments => !this.selectedAppointments.includes(appointments.id));
                this.selectedAppointments = [];
                this.fetchAppointments()
                if (this.appointments.length === 0) {
                    this.currentPage = this.currentPage - 1;
                    if (this.currentPage < 1) {
                        this.currentPage = 1;
                    }
                    this.fetchAppointments();
                }
            } catch (error) {
                console.error('Error deleting appointments:', error);
            }
        }
    },
});
</script>