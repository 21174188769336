<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <h1>{{ constants.HOME_PAGE_HERO_TEXT }}</h1>
                        <div class="form-content">
                            <div class="add-list-row">
                                <div v-for="(slide, index) in slides" :key="index" class="add-list-box" id="editBoxkOne"
                                    :class="{ 'add-list-show': slide.editing }">
                                    <div class="add-list-box-top">
                                        <h6>{{ slide.slide_title }}</h6>
                                        <ul>
                                            <li>
                                                <button id="editClickOne" @click="editSlide(index)">
                                                    {{ constants.EDIT_BUTTON }}
                                                </button>
                                            </li>
                                            <li>
                                                <button @click="deleteSlide(slide.id)">
                                                    {{ constants.DELETE_BUTTON }}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="add-list-box-btm" v-if="slide.editing">
                                        <div class="input-field input-width-95">
                                            <label> {{ constants.DESCRIPTION_LABEL }}</label>
                                            <input type="text" v-model="slide.slide_title" />
                                        </div>
                                        <div class="input-field input-width-95">
                                            <label> {{ constants.UPLOAD_IMAGE_LABEL }}</label>
                                            <div class="single-img-upload-row">
                                                <div class="single-img-upload-box img-uploaded">
                                                    <div class="single-img-upload">
                                                    </div>
                                                    <div class="single-img-uploaded">
                                                        <div class="Uploaded-img-edit">
                                                            <input type="file" @change="handleFileUpload($event, true)"
                                                                accept="image/*" />
                                                        </div>
                                                        <div class="single-img-uploaded-img">
                                                            <img :src="imageSrc || slide.slide_image" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-field-submit">
                                            <div class="button-center">
                                                <button class="secondary-outline-btn" @click="fetchSlides">
                                                    {{ constants.CANCEL_BUTTON }}
                                                </button>
                                                <button class="primary-outline-btn" @click="submitUpdate(slide)">
                                                    {{ constants.SUBMIT_BUTTON }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="add-new-item" :class="{ 'addnewitemshow': formOpen }" v-if="formOpen">

                                <div class="input-field input-width-95">
                                    <label> {{ constants.DESCRIPTION_LABEL }}</label>
                                    <input type="text" placeholder="Enter the text here" v-model="titleInput" />
                                </div>
                                <div class="input-field input-width-95">
                                    <label> {{ constants.UPLOAD_IMAGE_LABEL }}</label>
                                    <div class="single-img-upload-row">
                                        <div class="single-img-upload-box">
                                            <div class="single-img-upload">
                                                <div class="single-img-upload-icon" v-if="!imageSrcAdd">
                                                    <i>
                                                        <img src="../../assets/img/add-img-icon.svg" alt="" />
                                                    </i>
                                                    <span>{{ constants.ADD_IMAGE_LABEL }}</span>
                                                </div>
                                                <div class="single-img-upload-input">
                                                    <input type="file" @change="handleFileUpload($event)" accept="image/*">
                                                </div>
                                                <div class="single-img-uploaded-img">
                                                    <img :src="imageSrcAdd" alt=""
                                                        style="max-width: 100%;max-height: 100%;display: block;">
                                                </div>
                                            </div>
                                            <div class="single-img-uploaded" v-if="imageSrcAdd">
                                                <div class="Uploaded-img-edit">
                                                    <input type="file" @change="handleFileUpload($event)"
                                                        accept="image/*" />
                                                </div>
                                                <div class="single-img-uploaded-img">
                                                    <img :src="imageSrcAdd" alt=""
                                                        style="max-width: 100%; max-height: 100%; display: block;" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-field-submit">
                                    <div class="button-center">
                                        <button type="button" class="secondary-outline-btn"
                                            @click="reset(); formOpen = !formOpen">
                                            {{ constants.CANCEL_BUTTON }}
                                        </button>
                                        <button class="primary-outline-btn" @click="submitForm">
                                            {{ constants.SUBMIT_BUTTON }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="add-more-row-btn">
                                <button class="add-more-row-btn" id="addNewItem"
                                    @click="reset(); formOpen = !formOpen">{{ constants.PLUS }}
                                    {{ constants.ADD_MORE_BUTTON }}
                                </button>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">{{ constants.COPYRIGHT_TEXT }}</div>
        </footer>
    </section>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import constants from '@/utils/en-US-Admin.json';
import axios from 'axios';
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import customJSFunction from "@/assets/js/custom.js";
import { API_BASE_URL } from '@/config/api.config';


interface Slide {
    id: number;
    slide_title: string;
    slide_image: string;
    editing?: boolean;
}


export default defineComponent({
    name: 'HomePageHeroView',
    setup() {
        const activeIcon = ref('adminHome');

        return {
            activeIcon,
        };
    },
    data() {
        return {
            constants,
            first_name: '',
            titleInput: '',
            selectedFile: null as File | null,
            slides: [] as Slide[],
            imageSrc: '',
            imageSrcAdd: '',
            formOpen: false

        };
    },
    components: {
        HeaderComponent,
        FooterComponent,
        LeftSidebarComponent,
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    methods: {
        editSlide(index: number) {
            this.slides.forEach((slide, i) => {
                slide.editing = i === index ? !slide.editing : false;
            });
        },
        prepareFormData(slide: Slide) {
            const formData = new FormData();
            formData.append('slide_title', slide.slide_title);
            if (this.selectedFile instanceof File) {
                formData.append('slide_image', this.selectedFile);
                this.makeApiCall(slide, formData);
            } else {
                     const url = new URL(slide.slide_image);
                     url.protocol = 'https:';
                     const httpsUrl = url.href;

                     fetch(httpsUrl).then(res => res.blob()).then(blob => {
                     const file = new File([blob], 'image.jpg', blob);
                     formData.append('slide_image', file);
                     this.makeApiCall(slide, formData);
                     });
                console.log(slide.slide_image, ">>>>>> API HIT")
               // fetch(slide.slide_image).then(res => res.blob()).then(blob => {
                 //   const file = new File([blob], 'image.jpg', blob);
                  //  formData.append('slide_image', file);
                  //  console.log(slide.slide_image, ">>>>>> API HIT MAKE API CALL")
                   // this.makeApiCall(slide, formData);
              //  });
            }
            return formData;
        },
        submitUpdate(slide: Slide) {
            const formData = this.prepareFormData(slide);
        },
        makeApiCall(slide: Slide, formData: FormData) {
            axios.put(`${API_BASE_URL}/homepage_hero/${slide.id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    this.$store.commit('showSubmitSuccess')
                    this.$store.commit('hideSubmit')
                        setTimeout(() => {
                        this.$store.commit('hideSubmitSuccess')
                        }, 10000)
                    // this.fetchSlides();
                    slide.editing = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        handleFileUpload(event: Event, isEdit = false) {
            const target = event.target as HTMLInputElement;
            if (target && target.files && target.files.length > 0) {
                this.selectedFile = target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(this.selectedFile);
                reader.onload = (e) => {
                    if (e.target && typeof e.target.result === 'string') {
                        if (isEdit) {
                            this.imageSrc = e.target.result;
                        } else {
                            this.imageSrcAdd = e.target.result;
                        }
                    }
                }
            }
        },
        submitForm() {
            const formData = new FormData();
            if (this.selectedFile instanceof File) {
                formData.append('slide_image', this.selectedFile);
            }
            formData.append('slide_title', this.titleInput);

            if (this.titleInput.trim() && this.selectedFile) {
                axios.post(`${API_BASE_URL}/homepage_hero/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(response => {
                        this.$store.commit('showSubmitSuccess')
                        this.$store.commit('showSubmit')
                        setTimeout(() => {
                        this.$store.commit('hideSubmitSuccess')
                        }, 10000)
                        this.fetchSlides();
                        this.reset();
                        this.formOpen = false;
                        this.selectedFile = null;

                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                alert('Please fill in both title and image');
            }
        },
        fetchSlides() {
            axios.get(`${API_BASE_URL}/homepage_hero/`)
                .then(response => {
                    this.slides = response.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id);
                    if (this.slides.length === 0) {
                        this.formOpen = true;
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        async deleteSlide(slideId: number) {
            try {
                await axios.delete(`${API_BASE_URL}/homepage_hero/${slideId}/`);
                // this.slides = this.slides.filter(slide => slide.id !== slideId);
                this.fetchSlides();
            } catch (error) {
                console.error('Error deleting slide:', error);
            }
        },
        reset() {
            this.titleInput = '';
            this.imageSrc = '';
            this.imageSrcAdd = '';
        }
    },
    created() {
        const firstName = localStorage.getItem('firstname');
        if (firstName) {
            this.first_name = firstName;
        }
        this.fetchSlides();
    },
});
</script>

