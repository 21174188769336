<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <h1>{{ constants.WHAT_WILL_YOU_GAIN_TEXT }}</h1>
                        <div class="form-content">
                            <form @submit.prevent="submitForm">
                                <div class="input-field input-width-95">
                                    <label> {{ constants.TITLE_LABEL }}</label>
                                    <input type="text" placeholder="Enter the text here" v-model="benefits.title" />
                                </div>
                                <div class="input-field input-width-95">
                                    <label> {{ constants.DESCRIPTION_LABEL }}</label>
                                    <ckeditor placeholder="Description" class="text-editer" :editor="editor"
                                        v-model="benefits.description" :config="editorConfig">
                                    </ckeditor>
                                    <!-- <textarea placeholder="Description" v-model="benefits.description" ></textarea> -->
                                </div>
                                <div class="input-field-submit">
                                    <div class="button-center ">
                                        <button class="secondary-outline-btn">{{ constants.CANCEL_BUTTON }}</button>
                                        <button class="primary-outline-btn"
                                            type="submit">{{ constants.SUBMIT_BUTTON }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">
                {{ constants.COPYRIGHT_TEXT }}
            </div>
        </footer>
    </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import axios from 'axios';
import constants from '@/utils/en-US-Admin.json';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default defineComponent({
    components: {
        HeaderComponent,
        FooterComponent,
        LeftSidebarComponent,
    },
    data() {
        return {
            constants: constants,
            benefits: {
                id: '',
                title: '',
                description: ''
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        'insertTable',
                    ]
                },
            }

        }
    },
    mounted() {
        this.fetchBenefits();
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    methods: {
        async fetchBenefits() {
            try {
                const response = await axios.get(`${API_BASE_URL}/benefits/`);
                if (response.data && response.data.length > 0) {
                    this.benefits = response.data[0];
                }
                else {
                    this.benefits = {
                        id: '',
                        title: '',
                        description: ''
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        async submitForm() {
            const formData = new FormData();
            try {
                if (this.benefits.id) {
                    await axios.put(`${API_BASE_URL}/benefits/${this.benefits.id}/`, this.benefits);
this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)               
 } else {
                    formData.append('title', this.benefits.title);
                    formData.append('description', this.benefits.description);
                    await axios.post(`${API_BASE_URL}/benefits/`, formData);
this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    setup() {
        const activeIcon = ref('admin-benefits-to-gain');

        return {
            activeIcon,
        };
    },
});
</script>
