<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <h1>

                            {{ constants.BLOGS_TEXT }}
                        </h1>
                        <div class="form-content">
                            <div class="add-list-row">
                                <!-- Loop Box Start-->
                                <div class="add-list-box" id="editBoxkOne" v-for="(blog, index) in BlogData"
                                    :class="{ 'add-list-show': blog.editing }" :key="index">
                                    <div class="add-list-box-top">
                                        <h6>{{ blog.title }}</h6>
                                        <ul>
                                            <li>
                                                <button v-if="blog.status != 1" @click="submitUpdate(blog, 1)">
                                                    {{ constants.PUBLISH_BUTTON }}
                                                </button>
                                                <button v-else-if="blog.status == 1" @click="submitUpdate(blog, 0)">
                                                    {{ constants.UNPUBLISH_BUTTON }}
                                                </button>
                                            </li>
                                            <li>
                                                <button id="editClickOne" @click="editBlog(index)">

                                                    {{ constants.EDIT_BUTTON }}
                                                </button>
                                            </li>
                                            <li>
                                                <button @click="deleteSlide(blog.id)">

                                                    {{ constants.DELETE_BUTTON }}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- After edit button click show this section -->
                                    <div class="add-list-box-btm" v-if="blog.editing">
                                        <div class="input-field ">

                                            <label> {{ constants.TITLE_LABEL }}</label>

                                            <input type="text" placeholder="Enter the text here" v-model="blog.title">
                                        </div>
                                        <div class="input-field ">

                                            <label> {{ constants.SHORT_DESCRIPTION_LABEL }}</label>

                                            <textarea placeholder="Description"
                                                v-model="blog.short_description"></textarea>
                                        </div>
                                        <div class="input-field ">

                                            <label> {{ constants.DESCRIPTION_LABEL }}</label>

                                            <ckeditor placeholder="Description" class="text-editer" :editor="editor"
                                                v-model="blog.description" :config="editorConfig">
                                            </ckeditor>
                                            <!-- <textarea placeholder="Description" v-model="blog.description"></textarea> -->
                                        </div>
                                        <div class="input-field ">

                                            <label> {{ constants.UPLOAD_IMAGE_LABEL }}</label>

                                            <div class="multiple-image-upload-row same-size-img"
                                                v-if="!blog.BlogImage && !BlogImage">
                                                <div class="multiple-image-box add-multiple-image">
                                                    <div class="multiple-img-upload-icon">
                                                        <i>
                                                            <img src="../../assets/img/add-img-icon.svg" alt="">
                                                        </i>
                                                        <span>{{constants.ADD_IMAGE_LABEL}}</span>
                                                    </div>
                                                    <div class="multiple-img-upload-input">
                                                        <input type="file" @change="handleFileUpload($event, true)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="multiple-image-upload-row same-size-img"
                                                v-if="blog.BlogImage || BlogImage">
                                                <div class="multiple-image-box multiple-image-upload">
                                                    <button class="Uploaded-img-delete"
                                                        @click="blog.BlogImage = ''"></button>
                                                    <div class="multiple-img-uploaded-img">
                                                        <img :src="blog.BlogImage || BlogImage" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-field-submit">
                                            <div class="button-center ">
                                                <button class="secondary-outline-btn" @click="cancelEdit(blog)">
                                                    {{ constants.CANCEL_BUTTON }}
                                                </button>
                                                <button class="primary-outline-btn" v-if="blog.status === 0"
                                                    @click="submitUpdate(blog, 1)">
                                                    {{ constants.PUBLISH_BUTTON }}
                                                </button>
                                                <button class="primary-outline-btn" v-else-if="blog.status === 1"
                                                    @click="submitUpdate(blog, 0)">
                                                    {{ constants.UNPUBLISH_BUTTON }}
                                                </button>
                                                <button class="primary-outline-btn"
                                                    @click="submitUpdate(blog, blog.status)">
                                                    {{ constants.SUBMIT_BUTTON }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- After edit button click show this section -->
                                </div>
                                <!-- Loop Box End-->
                            </div>
                            <!-- Add new Form and click Add more button show -->
                            <div :class="{ 'addnewitemshow': formOpen }" v-if="formOpen">
                                <div class="add-list-bg">
                                    <div class="input-field ">

                                        <label> {{ constants.TITLE_LABEL }}</label>

                                        <input type="text" placeholder="Enter the text here" v-model="title">
                                        <!-- <span class="error-text" v-if="titleError">Title is required</span> -->
                                    </div>
                                    <div class="input-field ">

                                        <label> {{ constants.SHORT_DESCRIPTION_LABEL }}</label>

                                        <textarea placeholder="Description" v-model="short_description"></textarea>
                                    </div>
                                    <div class="input-field ">

                                        <label> {{ constants.DESCRIPTION_LABEL }}</label>

                                        <ckeditor placeholder="Description" class="text-editer" :editor="editor"
                                            v-model="description" :config="editorConfig">
                                        </ckeditor>
                                        <!-- <textarea placeholder="Description" v-model="description"></textarea> -->
                                    </div>
                                    <div class="input-field ">

                                        <label> {{ constants.UPLOAD_IMAGE_LABEL }}</label>

                                        <div class="multiple-image-upload-row same-size-img" v-if="!imageSrc">
                                            <div class="multiple-image-box add-multiple-image">
                                                <div class="multiple-img-upload-icon">
                                                    <i>
                                                        <img src="../../assets/img/add-img-icon.svg" alt="">
                                                    </i>
                                                    <span>{{constants.ADD_IMAGE_LABEL}}</span>
                                                </div>
                                                <div class="multiple-img-upload-input">
                                                    <input type="file" @change="handleFileUpload">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="multiple-image-upload-row same-size-img" v-if="imageSrc">
                                            <div class="multiple-image-box multiple-image-upload">
                                                <button class="Uploaded-img-delete"
                                                    @click="imageSrc = ''; selectedFile = null"></button>
                                                <div class="multiple-img-uploaded-img">
                                                    <img :src="imageSrc" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-field-submit">
                                        <div class="button-center ">
                                            <button class="secondary-outline-btn" @click="handleCancelClick">
                                                {{ constants.CANCEL_BUTTON }}
                                            </button>
                                            <button class="primary-outline-btn" @click="handlePublishClick">
                                                {{ constants.PUBLISH_BUTTON }}
                                            </button>
                                            <button type="submit" class="primary-outline-btn"
                                                @click="handleSubmitClick">
                                                {{ constants.SUBMIT_BUTTON }}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- Add new Form -->
                            <div class="add-more-row-btn">
                                <button id="addNewItem" @click="reset(); formOpen = !formOpen">{{constants.PLUS}}

                                    {{ constants.ADD_MORE_BUTTON }}
                                </button>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        </section>
    </section>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import constants from '@/utils/en-US-Admin.json';
import axios from 'axios';
import customJSFunction from "@/assets/js/custom.js";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import HeaderComponent from "@/components/HeaderComponent.vue";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


interface BlogData {
    id: number;
    title: string;
    short_description: string;
    description: string;
    BlogImage: string;
    status: number;
    publishedDate: string;
    editing?: boolean;
}

export default defineComponent({
    name: 'AdminBlogView',
    setup() {
        const activeIcon = ref('adminBlog');

        return {
            activeIcon,
        };
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    data() {
        return {
            constants,
            // titleError: false,
            title: '',
            short_description: '',
            description: '',
            BlogImage: '',
            selectedFile: null as File | null,
            imageSrc: '',
            status: null as number | null,
            formOpen: false,
            BlogData: [] as BlogData[],
            selectedUpdatedFile: null as File | null,
            publishDate: null as Date | null,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        'insertTable',
                    ]
                },
            }
        };
    },
    components: {
        HeaderComponent,
        LeftSidebarComponent,
    },
    created() {
        this.fetchBlogs();
    },
    methods: {
        cancelEdit(blog: BlogData) {
            blog.editing = false;
        },
        handleCancelClick() {
            this.formOpen = false;
        },
        submitUpdate(blog: BlogData, status: number) {
            const formData = this.prepareFormData(blog, status);
        },
        async prepareFormData(blog: BlogData, status: number) {
            try {

                blog.status = status;
                const formData = new FormData();
                formData.append('title', blog.title);
                formData.append('short_description', blog.short_description);
                formData.append('description', blog.description);
                if (blog.status !== null) {
                    formData.append('status', blog.status.toString());
                }

                if (this.selectedUpdatedFile instanceof File) {
                    formData.append('BlogImage', this.selectedUpdatedFile);
                    this.makeApiCall(blog, formData);
                } else {
                   // fetch(blog.BlogImage).then(res => res.blob()).then(blob => {
                     //   const file = new File([blob], 'image.jpg', blob);
                      //  formData.append('BlogImage', file);
                      //  this.makeApiCall(blog, formData);
                  //  });
                    const url = new URL(blog.BlogImage);
                    url.protocol = 'https:';
                    const httpsUrl = url.href;

                    fetch(httpsUrl).then(res => res.blob()).then(blob => {
                        const file = new File([blob], 'image.jpg', blob);
                        formData.append('BlogImage', file);
                        this.makeApiCall(blog, formData);
                    });
                }
                return formData;
            } catch (error) {
                console.error(error);
            }
        },
        makeApiCall(blog: BlogData, formData: FormData) {
            this.publishDate = new Date();
            if (this.publishDate !== null) {
                formData.append('publishedDate', this.publishDate.toISOString());
            }
            axios.put(`${API_BASE_URL}/blogs/${blog.id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    this.fetchBlogs();
                    this.formOpen = false;
                    this.imageSrc = '';
                    this.selectedFile = null;
this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)

                    blog.editing = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        editBlog(index: number) {
            this.BlogData.forEach((blog, i) => {
                blog.editing = i === index ? !blog.editing : false;
            });
        },
        handlePublishClick() {
            this.status = 1;
            this.publishDate = new Date();
            this.PostBlogs()
        },
        handleSubmitClick() {
            this.status = 0;
            // if (!this.title) {
            //         this.titleError = true;
            //         return;
            //     }else{
            //         this.titleError = false;
            //     }
            this.PostBlogs()
        },
        async PostBlogs() {
            try {
                const formData = new FormData();
                formData.append('title', this.title);
                formData.append('short_description', this.short_description);
                formData.append('description', this.description);

                if (this.publishDate !== null) {
                    formData.append('publishedDate', this.publishDate.toISOString());
                }

                if (this.status !== null) {
                    formData.append('status', this.status.toString());
                }
                if (this.selectedFile instanceof File) {
                    formData.append('BlogImage', this.selectedFile);
                }
if (this.title.trim() && this.short_description.trim() && this.description.trim() && this.selectedFile) {
                    const response = await axios.post(`${API_BASE_URL}/blogs/`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    this.formOpen = false;
                    this.selectedFile = null;
                    this.fetchBlogs();
                    this.$store.commit('showSubmitSuccess')
                    this.$store.commit('showSubmit')
                    setTimeout(() => {
                      this.$store.commit('hideSubmitSuccess')
                     }, 10000)
                } else {
                    alert('Please fill in all required fields');
                }

        
            } catch (error) {
                console.error(error);
            }
        },
        handleFileUpload(event: any, check?: boolean) {
            if (!check) {
                const target = event.target as HTMLInputElement;
                if (target && target.files && target.files.length > 0) {
                    this.selectedFile = target.files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(this.selectedFile);
                    reader.onload = (e) => {
                        if (e.target && typeof e.target.result === 'string') {
                            this.imageSrc = e.target.result;
                        }
                    }
                }
            } else {
                const target = event.target as HTMLInputElement;
                if (target && target.files && target.files.length > 0) {
                    this.selectedUpdatedFile = target.files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(this.selectedUpdatedFile);
                    reader.onload = (e) => {
                        if (e.target && typeof e.target.result === 'string') {
                            this.BlogImage = e.target.result;
                        }
                    }
                }
            }
        },
        reset() {
            this.title = '';
            this.short_description = '';
            this.description = '';
            this.imageSrc = '';
        },
        async fetchBlogs() {
            try {
                const response = await axios.get(`${API_BASE_URL}/blogs/`);
                this.BlogData = response.data.sort((a: { id: number }, b: { id: number }) => b.id - a.id);
                if (this.BlogData.length === 0) {
                    this.formOpen = true;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async deleteSlide(BlogId: number) {
            try {
                await axios.delete(`${API_BASE_URL}/blogs/${BlogId}/`);
                // this.slides = this.slides.filter(slide => slide.id !== slideId);
                this.fetchBlogs();
            } catch (error) {
                console.error('Error deleting BLog:', error);
            }
        },
    }
})
</script>
