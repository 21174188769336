import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "site-footer" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "custom-row" }
const _hoisted_4 = { class: "site-footer-left" }
const _hoisted_5 = { href: "/" }
const _hoisted_6 = {
  "data-bs-toggle": "modal",
  "data-bs-target": "#ourMission"
}
const _hoisted_7 = {
  href: "#",
  "data-bs-toggle": "modal",
  "data-bs-target": "#getTouch"
}
const _hoisted_8 = { class: "site-footer-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.constants.HOME_MENU), 1)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.constants.ABOUT_ME_MENU), 1)
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, { to: "/services" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.constants.SERVICES_MENU), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, { to: "/blog" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.constants.BLOG_MENU), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.constants.CONTACT_US_MENU), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", null, _toDisplayString(_ctx.constants.COPYRIGHT_TEXT), 1)
        ])
      ])
    ])
  ]))
}