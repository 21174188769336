<template>
    <section id="mainContainer">
        <section class="login-page">
            <div class="login-container">
                <div class="logo-login">
                    <img src="../../assets/img/login-logo.svg" alt="">
                </div>
                <div class="login-form">
                    <h1>
                        {{ constants.LOGIN_TEXT }}
                    </h1>
                    <form @submit.prevent="login">
                        <div class="input-field" :class="{ error: titleErrorUsername }">
                            <input type="text" v-model="username" placeholder="username">
                            <span class="error-text" v-if="titleErrorUsername">{{ errorMsg
                                }}</span>
                        </div>
                        <div class="input-field" :class="{ error: titleErrorPassword }">
                            <input type="password" v-model="password" placeholder="password">
                            <span class="error-text" v-if="titleErrorPassword">{{ errorMsg
                                }}</span>
                        </div>
                        <div class="input-checkbox">
                            <div class="checkbox">
                                <input name="cssCheckbox" id="demo_opt_1" type="checkbox" class="css-checkbox"
                                    v-model="rememberMe">
                                <label for="demo_opt_1">
                                    {{ constants.REMEMBER_ME_LABEL }}
                                </label>
                            </div>
                        </div>
                        <div class="input-field-submit">
                            <button class="primary-outline-btn width-100" formaction="home-page-hero.html">
                                {{ constants.SUBMIT_BUTTON }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <footer class="site-footer">
            <div class="container">
                {{ constants.COPYRIGHT_TEXT }}
            </div>
        </footer>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import constants from '@/utils/en-US-Admin.json';
import axios from 'axios';
import { API_BASE_URL } from '@/config/api.config';


export default defineComponent({
    name: 'LoginView',
    data() {
        return {
            constants,
            username: '',
            password: '',
            rememberMe: false,
            titleErrorUsername: false,
            titleErrorPassword: false,
            errorMsg: '',
            submitClicked: false,

        };
    },
    watch: {
        username() {
            if (this.submitClicked) {
                this.titleErrorUsername = !this.username;
                if (this.errorMsg !== constants.INVALID_USER_NAME || this.errorMsg !== constants.INVALID_PASSWORD) {
                    this.errorMsg = constants.THIS_FIELD_IS_REQUIRED;
                }
            }
        },
        password() {
            if (this.submitClicked) {
                this.titleErrorPassword = !this.password;
                if (this.errorMsg !== constants.INVALID_USER_NAME || this.errorMsg !== constants.INVALID_PASSWORD) {
                    this.errorMsg = constants.THIS_FIELD_IS_REQUIRED;
                }
            }
        },
    },
    mounted() {
        if (localStorage.getItem('isLoggedIn') === 'true') {
            this.$router.push('/user/home');
        } else {
            localStorage.clear();
        }
    },
    methods: {
        async login() {
            this.submitClicked = true;
            if (!this.username) {
                this.titleErrorUsername = true;
                this.errorMsg = constants.THIS_FIELD_IS_REQUIRED;
            } else {
                this.titleErrorUsername = false;
            }
            if (!this.password) {
                this.titleErrorPassword = true;
                this.errorMsg = constants.THIS_FIELD_IS_REQUIRED;
            } else {
                this.titleErrorPassword = false;
            }

            if (!this.username || !this.password) {
                return;
            }

            try {
                const response = await axios.post(`${API_BASE_URL}/adminLogin/`, {
                    username: this.username,
                    password: this.password
                });
                this.$store.commit('showPopup')
                setTimeout(() => {
                    this.$store.commit('hidePopup')
                }, 10000)

                // Handle successful response
                const tokens = response.data;
                // Do something with tokens, like storing them in local storage

                localStorage.setItem('accessToken', tokens.access);
                localStorage.setItem('refreshToken', tokens.refresh);
                localStorage.setItem('firstname', tokens.first_name);
                localStorage.setItem('isLoggedIn', "true");

                this.$router.push('/user/home');
            } catch (error: any) {
                if (error.response) {
                    if (error.response.data.error === 'Invalid Username') {
                        this.titleErrorUsername = true;
                        this.errorMsg = constants.INVALID_USER_NAME;
                    } else if (error.response.data.error === 'Invalid Password') {
                        this.titleErrorPassword = true;
                        this.errorMsg = constants.INVALID_PASSWORD;
                    } else {
                        this.titleErrorUsername = true;
                        this.titleErrorPassword = true;
                        this.errorMsg = "Invalid Credentials";
                    }
                }
            }
        }
    }
});
</script>
