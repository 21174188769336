<template>
    <section id="mainContainer" :class="{ 'mobile_nav_class': isActive }">
        <HeaderComponent></HeaderComponent>
        <section class="mid-container">
            <section class="page-hero-section"
                :style="{ 'background-image': `url(${require('../../assets/img/dummy/payment-banner.jpg')})` }">
                <div class="container">
                    <h1>{{ constants.PAYMENT_DETAIL_TEXT }}</h1>
                </div>
            </section>
            <section class="payment-detail-page">
                <div class="payment-container">
                    <div class="payment-detail-page-bg">
                        <div id="" class="payment-detail-content payment-step-one" v-if="!showSteptwo">
                            <h2>{{ constants.USER_DETAIL_TEXT }}</h2>
                            <div class="input-field" :class="{ error: titleError && !firstName }">
                                <input type="text" placeholder="First Name" v-model="firstName">
                                <span class="error-text" v-if="titleError && !firstName">{{
                                    constants.THIS_FIELD_IS_REQUIRED }}</span>
                            </div>
                            <div class="input-field" :class="{ error: titleError && !lastName }">
                                <input type="text" placeholder="Last Name" v-model="lastName" />
                                <span class="error-text" v-if="titleError && !lastName">{{
                                    constants.THIS_FIELD_IS_REQUIRED }}</span>
                            </div>
                            <div class="input-field" :class="{ error: (titleError && !Email) || (emailError.length) }">
                                <input type="email" placeholder="Email ex.'xyz@gmail.com'" v-model="Email"
                                    @input="validateEmail(nextClicked)">
                                <span class="error-text" v-if="titleError && !Email">{{ constants.THIS_FIELD_IS_REQUIRED
                                    }}</span>
                                <span class="error-text" v-if="!(titleError && !Email)">{{ emailError }}</span>
                            </div>
                            <div class="input-field" :class="{ error: titleError && !number }">
                                <input type="tel" placeholder="Phone Number" v-model="number"
                                    @keypress="preventNonNumeric($event)" />
                                <span class="error-text" v-if="titleError && !number">{{
                                    constants.THIS_FIELD_IS_REQUIRED }}</span>
                            </div>
                            <div class="input-field" :class="{ error: titleError && !Amount }">
                                <input type="text" placeholder="Amount" v-model="Amount"
                                    @keypress="preventNonNumeric($event)" />
                                <span class="error-text" v-if="titleError && !Amount">{{
                                    constants.THIS_FIELD_IS_REQUIRED }}</span>
                            </div>
                            <div class="input-field" :class="{ error: titleError && !selectedOption }">
                                <select class="selectbox" v-model="selectedOption">
                                    <option>{{ constants.WEEKLY_NEWSLETTER_SUBSCRIPTION }}</option>
                                    <option>{{ constants.ONE_ON_ONE_COUNSELLING }}</option>
                                    <option>{{ constants.OTHERS }}</option>
                                </select>
                                <span class="error-text" v-if="titleError && !selectedOption">{{
                                    constants.THIS_FIELD_IS_REQUIRED }}</span>
                            </div>
                            <div class="input-field">
                                <label class="optional-label"><span>{{ constants.OPTIONAL_LABEL }}</span></label>
                                <textarea placeholder="Add your comment" v-model="comment"></textarea>
                            </div>
                            <div class="input-field-submit">
                                <div class="button-center">
                                    <!-- <button type="button" class="secondary-outline-btn">Cancel</button> -->
                                    <button type="button" class="primary-outline-btn" id="nextStep"
                                        @click="fetchPaymentQRCode(); submitPaymentForm(false)">{{ constants.NEXT_TEXT }}</button>
                                </div>
                            </div>
                        </div>
                        <div id="" class="payment-detail-content " v-if="showSteptwo">
                            <div class="bar-code">
                                <img v-if="mostRecentQRImageUrl" :src="mostRecentQRImageUrl" alt="QR Code"
                                    width="150px">
                                <img v-else src="" alt="Please Update with a QR Code" />
                            </div>
                            <div class="pay-name">{{ constants.PAY_DIKSHA_TEXT }}</div>
                            <div class="pay-info">{{
                                constants.USE_ANY_UPI_APP_TO_SCAN_ABOVE_QR_CODE_AND_COMPLETE_THE_PAYMENT_TEXT }}</div>
                            <div class="payment-way-img">
                                <img src="../../assets/img/payment-way-img.jpg" />
                            </div>
                            <form @submit.prevent="submitPaymentForm(true)">
                                <div class="input-field" :class="{ error: titleError && !transactionId }">
                                    <input type="text" placeholder="Enter the transaction id" v-model="transactionId">
                                    <span class="error-text" v-if="titleError && !transactionId">{{
                                        constants.THIS_FIELD_IS_REQUIRED }}</span>


                                </div>
                                <div class="input-field-submit">
                                    <div class="button-center">
                                        <button type="button" class="secondary-outline-btn"
                                            @click="showSteptwo = false">{{ constants.BACK }}</button>
                                        <button type="submit"
                                            class="primary-outline-btn">{{ constants.SUBMIT_BUTTON }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </section>
        <FooterComponent></FooterComponent>
    </section>
    <AboutMeComponent></AboutMeComponent>
    <ContactUsComponent></ContactUsComponent>
    <AppointmentComponent></AppointmentComponent>
    <BenefitsOfSubComponent></BenefitsOfSubComponent>
</template>

<script lang="ts">
import { computed, defineComponent, onUpdated, ref } from 'vue';
import customJSFunction from "@/assets/js/custom.js";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import AboutMeComponent from "@/components/AboutMeComponent.vue";
import ContactUsComponent from "@/components/ContactUsComponent.vue";
import AppointmentComponent from '@/components/AppointmentComponent.vue';
import BenefitsOfSubComponent from '@/components/BenefitsOfSubComponent.vue';
import constants from '@/utils/en-US-User.json';
import axios from 'axios';
import { API_BASE_URL } from '@/config/api.config';
import store from '@/store';
import $ from 'jquery'
import { environment } from '@/config/environment';

interface QRPhotoSchema {
    id: number;
    QRimage: string;
}

interface FormData {
    FirstName: string;
    LastName: string;
    Email: string;
    Number: string;
    Amount: string;
    Purpose: string;
    Comments: string;
    TransactionID?: string;
}
export default defineComponent({
    name: 'PayView',
    data() {
        return {
            constants,
            QRPhoto: [] as QRPhotoSchema[],
            firstName: '',
            lastName: '',
            Email: '',
            number: '',
            Amount: '',
            selectedOption: '',
            comment: '',
            transactionId: '',
            mostRecentQRImageUrl: '' as string | null,
            showSteptwo: false,
            paymentId: '',
            nextFlag: true,
            titleError: false,
            emailError: '',
            nextClicked: false,
            isFormSubmitted: false,


        };
    },
    components: {
        HeaderComponent,
        FooterComponent,
        AboutMeComponent,
        ContactUsComponent,
        AppointmentComponent,
        BenefitsOfSubComponent,
    },
    mounted() {
        customJSFunction();
    },
    props: {
        email: String
    },
    setup() {
        const isActive = computed(() => store.state.isActive);


        return {
            isActive
        };
    },
    created() {
        if (typeof this.email === 'string') {
            this.Email = this.email;
            this.Amount = '99';
            this.selectedOption = constants.WEEKLY_NEWSLETTER_SUBSCRIPTION;

        } else {
            this.Email = '';
            this.Amount = '';
            this.selectedOption = '';
        }
    },
    methods: {
        preventNonNumeric(event: any) {
            const keyCode = event.keyCode ? event.keyCode : event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 43 && keyCode !== 45 && keyCode !== 101) { // 43 is for '+', 45 is for '-', 101 is for 'e'
                event.preventDefault();
            }
        },
        validateEmail(nextClicked: boolean) {
            if (nextClicked) {
                if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.Email))) {
                    this.emailError = constants.VALID_EMAIL;
                } else {
                    this.emailError = '';
                }
            }
        },

        async submitPaymentForm(saveTransactionId: boolean) {
            try {
                this.nextClicked = true;
                this.validateEmail(this.nextClicked);
                if (!this.firstName || !this.lastName || !this.Email || !this.number || !this.Amount || !this.selectedOption) {
                    this.titleError = true;
                    return;
                } else {
                    this.titleError = false;
                }

                if (saveTransactionId && !this.transactionId) {
                    this.titleError = true;
                    return;
                } else {
                    this.titleError = false;
                }

                const data: FormData = {
                    FirstName: this.firstName,
                    LastName: this.lastName,
                    Email: this.Email,
                    Number: this.number,
                    Amount: this.Amount,
                    Purpose: this.selectedOption,
                    Comments: this.comment,
                };

                if (saveTransactionId) {
                    data.TransactionID = this.transactionId;
                }
                if (!saveTransactionId && this.nextFlag) {
                    const response = await axios.post(`${API_BASE_URL}/payment/`, data);
                    this.paymentId = response.data.id;
                    this.nextFlag = false;
                    // this.$store.commit('showPopup')
                    // setTimeout(() => {
                    //     this.$store.commit('hidePopup')
                    // }, 10000)
                }
                if (this.transactionId) {
                    await this.updateTransactionId(this.paymentId, this.transactionId);
                }
                if (saveTransactionId && this.transactionId) {
                    this.showSteptwo = false;
                    this.firstName = '';
                    this.lastName = '';
                    this.Email = '';
                    this.number = '';
                    this.Amount = '';
                    this.selectedOption = '';
                    this.comment = '';
                    this.transactionId = '';
                    this.paymentId = '';
                    this.nextFlag = true;
                    this.nextClicked = false;


                }

                // Handle success or additional logic
            } catch (error) {
                console.error(error);
            }
        },
        async updateTransactionId(paymentId: any, transactionId: any) {
            try {
                await axios.put(`${API_BASE_URL}/update_transaction_id/`, {
                    id: paymentId,
                    TransactionID: transactionId,
                });
                this.$store.commit('showPopup')
                setTimeout(() => {
                    this.$store.commit('hidePopup')
                }, 10000)

                // Handle success or additional logic
            } catch (error) {
                console.error(error);
            }
        },
        async fetchPaymentQRCode() {
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

            if (this.firstName && this.lastName && this.Email && this.number && this.Amount && this.selectedOption) {
                if (!emailRegex.test(this.Email)) {
                    this.titleError = true;
                    return;
                }

                this.showSteptwo = true;
            }
            try {
                const response = await axios.get(`${API_BASE_URL}/paymentQR/`);
                this.QRPhoto = response.data.sort((a: { id: number }, b: { id: number }) => b.id - a.id);
                if (this.QRPhoto.length > 0) {
                    this.mostRecentQRImageUrl = this.QRPhoto[0].QRimage;
                } else {
                    this.mostRecentQRImageUrl = null;
                }
            } catch (error) {
                console.error(error);
            }
        },
    }
});
</script>
