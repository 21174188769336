<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <h1>
                            {{ constants.ABOUT_ME_TEXT }}
                        </h1>
                        <div class="form-content">
                            <form @submit.prevent="handleSubmit">
                                <div class="input-field input-width-95">

                                    <label> {{ constants.TITLE_LABEL }}</label>

                                    <input type="text" placeholder="Enter the text here" v-model="aboutUsData.title">
                                </div>
                                <div class="input-field input-width-95">

                                    <label> {{ constants.BRIEF_DESCRIPTION_LABEL }}</label>

                                    <textarea placeholder="Description"
                                        v-model="aboutUsData.brief_description"></textarea>
                                </div>
                                <div class="input-field input-width-95">

                                    <label> {{ constants.DESCRIPTION_LABEL }}</label>

                                    <ckeditor class="text-editer" :editor="editor" v-model="aboutUsData.description"
                                        :config="editorConfig">
                                    </ckeditor>
                                    <!-- <div class="text-editer">
                                        <textarea placeholder="Description" v-model="aboutUsData.description"></textarea>
                                    </div> -->
                                </div>
                                <div class="input-field input-width-95">

                                    <label> {{ constants.SOCIAL_MEDIA_LABEL }}</label>

                                    <div class="social-media-url-input-row">
                                        <div class="social-media-url-input"
                                            v-for="(option, index) in socialMediaOptions" :key="index">
                                            <i>
                                                <img :src="require(`../../assets/img/${option.name}-input-icon.jpg`)"
                                                    alt=""> </i>
                                            <input type="text" placeholder="url" v-model="option.url" />
                                            <button class="social-media-url-remove"
                                                @click="deleteSocialMedia(option.name)">
                                                <img src="../../assets/img/gray-close-icon.svg" alt="" />
                                            </button>
                                        </div>
                                        <div class="add-more-row-btn">
                                            <button id="addNewItem" data-bs-toggle="modal"
                                                data-bs-target="#selectSocialMedia">{{constants.PLUS}}
                                                {{ constants.ADD_MORE_BUTTON }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-field input-width-95">

                                    <label> {{ constants.UPLOAD_IMAGE_LABEL }}</label>

                                    <div class="multiple-image-upload-row">
                                        <div class="multiple-image-box multiple-image-upload"
                                            v-for="(image, index) in aboutUsData.images" :key="index">
                                            <button type="button" class="Uploaded-img-delete"
                                                @click="deleteImage(image.id)"></button>
                                            <div class="multiple-img-uploaded-img">
                                                <img :src="image.image" alt="">
                                            </div>
                                        </div>
                                        <div class="multiple-image-box" v-for="(src, index) in imageSrc"
                                            :key="'image' + index">
                                            <img :src="src" alt="Preview"
                                                style="max-width: 100%; max-height: 100%; display: block;" />
                                            <button type="button" class="Uploaded-img-delete"
                                                @click="deleteImageFromSrc(index)"></button>
                                        </div>
                                        <div class="multiple-image-box add-multiple-image">
                                            <div class="multiple-img-upload-icon">
                                                <i>
                                                    <img src="../../assets/img/add-img-icon.svg" alt="">
                                                </i>
                                                <span>
                                                    {{ constants.ADD_IMAGE_LABEL }}
                                                </span>
                                            </div>
                                            <div class="multiple-img-upload-input">
                                                <input type="file" accept="image/*"
                                                    @change="handleFileUpload($event)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-field-submit">
                                    <div class="button-center ">
                                        <button type="button" class="secondary-outline-btn" @click="fetchData">
                                            {{ constants.CANCEL_BUTTON }}
                                        </button>
                                        <button type="submit" class="primary-outline-btn">
                                            {{ constants.SUBMIT_BUTTON }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">
                {{ constants.COPYRIGHT_TEXT }}
            </div>
        </footer>
    </section>



    <div class="modal fade small-pop select-social-media-pop" id="selectSocialMedia" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content select-social-media-pop-content">
                <h2>
                    {{ constants.SELECT_SOCIAL_MEDIA_TEXT }}
                </h2>
                <button class="close-pop-btn" data-bs-dismiss="modal" aria-label="Close" @click="popupCancel"></button>
                <div class="select-social-media">
                    <ul>
                        <li>
                            <input type="checkbox" id="myCheckbox1" v-model="selectedSocialMedia.facebook"
                                :checked="isFacebookSelected()" />
                            <label for="myCheckbox1">
                                <div class="checkbox-mark"></div>
                                <div>
                                    <div class="img-box">
                                        <img src="../../assets/img/logos_facebook.svg" alt="" />
                                    </div>
                                    <span>{{ constants.FACEBOOK_TEXT }}</span>
                                </div>

                            </label>
                        </li>
                        <li>

                            <input type="checkbox" id="myCheckbox2" v-model="selectedSocialMedia.linkedin"
                                :checked="isLinkedInSelected()" />
                            <label for="myCheckbox2">
                                <div class="checkbox-mark"></div>
                                <div>
                                    <div class="img-box">
                                        <img src="../../assets/img/logos_linkedin.svg" alt="" />
                                    </div>
                                    <span>{{ constants.LINKEDIN_TEXT }}</span>
                                </div>

                            </label>
                        </li>
                        <li>

                            <input type="checkbox" id="myCheckbox3" v-model="selectedSocialMedia.instagram"
                                :checked="isInstagramSelected()" />
                            <label for="myCheckbox3">
                                <div class="checkbox-mark"></div>
                                <div>
                                    <div class="img-box">
                                        <img src="../../assets/img/logos_instagram.svg" alt="" />
                                    </div>
                                    <span>{{ constants.INSTAGRAM_TEXT }}</span>
                                </div>

                            </label>
                        </li>
                        <li>

                            <input type="checkbox" id="myCheckbox4" v-model="selectedSocialMedia.twitter"
                                :checked="isTwitterSelected()" />
                            <label for="myCheckbox4">
                                <div class="checkbox-mark"></div>
                                <div>
                                    <div class="img-box">
                                        <img src="../../assets/img/logos_twitter.svg" alt="" />
                                    </div>
                                    <span>{{ constants.TWITTER_TEXT }}</span>
                                </div>

                            </label>
                        </li>
                    </ul>
                </div>
                <div class="input-field-submit">
                    <div class="button-center">
                        <button class="secondary-outline-btn" data-bs-dismiss="modal" @click="popupCancel">
                            {{ constants.CANCEL_BUTTON }}
                        </button>
                        <button class="primary-outline-btn" data-bs-dismiss="modal" @click="addSocialMedia">
                            {{ constants.SUBMIT_BUTTON }}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import constants from '../../utils/en-US-Admin.json';
import axios from 'axios';
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import customJSFunction from "@/assets/js/custom.js";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import _ from 'lodash';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



interface AboutUs {
    [key: string]: any;
    title: string;
    brief_description: string;
    description: string;
    social_media: Array<{ name: string; url: string }>;
    images: Array<{ id: number, image: string }>;
}



export default defineComponent({
    name: 'AboutUsView',
    data() {
        return {
            imagePreviewed: false,
            constants,
            aboutUsData: {
                id: 1,
                title: '',
                brief_description: '',
                description: '',
                social_media: [],
                images: [],
            } as AboutUs,
            fetchedData: {} as AboutUs,  // Add this line
            selectedSocialMedia: {
                facebook: false,
                linkedin: false,
                instagram: false,
                twitter: false
            },
            socialMediaOptions: [] as { name: string; url: string; }[],
            selectedFiles: [] as File[],
            imageSrc: [] as string[],
            imageDeletion: [] as { id: number; image: string; }[],
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        'insertTable',
                    ]
                },
            }


        };
    },
    watch: {
        socialMediaOptions: {
            handler(newVal) {
                this.aboutUsData.social_media = newVal.map((option: { name: string; url: string }) => ({ name: option.name, url: option.url }));
            },
            deep: true
        }
    },
    components: {
        HeaderComponent,
        FooterComponent,
        LeftSidebarComponent
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    mounted() {
        // customJSFunction();
        this.fetchData();
    },
    setup() {
        const activeIcon = ref('aboutUs');

        return {
            activeIcon,
        };
    },
    methods: {
        popupCancel() {
            this.selectedSocialMedia = {
                facebook: false,
                linkedin: false,
                instagram: false,
                twitter: false
            };
        },

        deleteImageFromSrc(index: number) {
            this.imageSrc.splice(index, 1);
        },

        async fetchData() {
            this.imageSrc = [];
            try {
                const response = await axios.get(`${API_BASE_URL}/aboutus/`);
                if (response.data.length > 0) {
                    this.aboutUsData = response.data[0];
                    this.fetchImages();
                    this.fetchedData = JSON.parse(JSON.stringify(response.data[0]));
                    this.socialMediaOptions = [...this.aboutUsData.social_media];
                } else {
                    console.log('No data received from the API');
                }
            } catch (error) {
                console.error(error);
            }
        },

        async deleteSocialMedia(name: string) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/aboutus/delete_social_media/`, { data: { name } });
                if (response.status === 204) {
                    this.fetchData();  // Refresh data after deletion
                } else {
                    console.error('Error deleting social media:', response.data);
                }
            } catch (error) {
                console.error(error);
            }
        },

        async deleteImage(id: number) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/images/${id}/`);
                this.fetchData();
            } catch (error) {
                console.error('Failed to delete image:', error);
            }
        },

        async fetchImages() {
            try {
                const response = await axios.get(`${API_BASE_URL}/images/`);
                this.aboutUsData.images = response.data;
                this.fetchedData.images = JSON.parse(JSON.stringify(response.data));

            } catch (error) {
                console.error(error);
            }
        },

        async createData() {
            const payload = {
                title: this.aboutUsData.title,
                brief_description: this.aboutUsData.brief_description,
                description: this.aboutUsData.description,
                social_media: this.aboutUsData.social_media.map(sm => ({ name: sm.name, url: sm.url })),
                images: this.aboutUsData.images
            };


            try {
                const response = await axios.post(`${API_BASE_URL}/aboutus/`, payload);
                this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)               
                this.fetchData();
                this.aboutUsData = response.data;
            } catch (error: any) {
                if ('response' in error) {
                    console.error('Error:', error.response.data);
                } else {
                    console.error(error);
                }
            }
        },


        async updateData(data: AboutUs) {
            try {
                const response = await axios.put(`${API_BASE_URL}/aboutus/`, data);
                this.aboutUsData = response.data;
                this.imageSrc = [];
                this.fetchData();
this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)
                this.imagePreviewed = false;
            } catch (error) {
                console.error(error);
            }
        },

        async partialUpdateData(data: Partial<AboutUs>) {
            try {
                const response = await axios.patch(`${API_BASE_URL}/aboutus/${this.aboutUsData.id}/`, data);
                this.aboutUsData = response.data;
                this.imageSrc = [];
                this.fetchData();
this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)
                this.imagePreviewed = false;
            } catch (error) {
                console.error(error);
            }
        },

        addSocialMedia() {
            for (const [key, value] of Object.entries(this.selectedSocialMedia)) {
                if (value) {
                    this.socialMediaOptions.push({ name: key, url: '' });
                    this.aboutUsData.social_media.push({ name: key, url: '' });
                    this.handleSubmit();
                }
            }
        },

        handleFileUpload(event: Event) {
            const target = event.target as HTMLInputElement;
            if (target && target.files && target.files.length > 0) {
                this.selectedFiles = Array.from(target.files);
                // this.imageSrc = [];
                for (const file of this.selectedFiles) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        if (e.target && typeof e.target.result === 'string') {
                            this.imageSrc.push(e.target.result);
                            this.imagePreviewed = true;
                            // this.aboutUsData.images.push({ image: e.target.result }); // Ensure you're pushing to the array
                        }
                    }
                }
            }
        },
        handleSubmit() {
            this.aboutUsData.images = [];
            this.imageSrc.forEach((src, index) => {
                this.aboutUsData.images.push({ id: this.aboutUsData.images.length + 1, image: src });
            });
            const allFieldsChanged = Object.keys(this.aboutUsData).every(key => {
                return this.aboutUsData[key] !== this.fetchedData[key];
            });

            if (Object.keys(this.fetchedData).length === 0) {
                this.createData();
                return;
            } else {
                const changedFields = Object.keys(this.aboutUsData).reduce((result, key) => {
                    if (!_.isEqual(this.aboutUsData[key], this.fetchedData[key])) {
                        result[key] = this.aboutUsData[key];
                    }
                    return result;
                }, {} as Partial<AboutUs>);

                this.partialUpdateData(changedFields);
            }
        },

        isFacebookSelected() {
            return this.aboutUsData.social_media.some(social => social.name.toLowerCase() === 'facebook');
        },
        isLinkedInSelected() {
            return this.aboutUsData.social_media.some(social => social.name.toLowerCase() === 'linkedin');
        },
        isInstagramSelected() {
            return this.aboutUsData.social_media.some(social => social.name.toLowerCase() === 'instagram');
        },
        isTwitterSelected() {
            return this.aboutUsData.social_media.some(social => social.name.toLowerCase() === 'twitter');
        }

    },
});
</script>
