import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal fade small-pop get-touch-pop",
  id: "getTouch",
  tabindex: "-1",
  role: "dialog",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = {
  class: "modal-dialog",
  role: "document"
}
const _hoisted_3 = { class: "modal-content get-touch-pop-content" }
const _hoisted_4 = { class: "sub-hd" }
const _hoisted_5 = { class: "get-touch-form" }
const _hoisted_6 = {
  key: 0,
  class: "error-text"
}
const _hoisted_7 = {
  key: 0,
  class: "error-text"
}
const _hoisted_8 = {
  key: 1,
  class: "error-text"
}
const _hoisted_9 = {
  key: 0,
  class: "error-text"
}
const _hoisted_10 = { class: "input-field" }
const _hoisted_11 = { class: "optional-label" }
const _hoisted_12 = { class: "input-field-submit" }
const _hoisted_13 = { class: "button-center" }
const _hoisted_14 = {
  type: "submit",
  class: "primary-outline-btn"
}
const _hoisted_15 = {
  type: "button",
  class: "dummy-btn",
  "data-bs-dismiss": "modal",
  ref: "dummyButton",
  style: {"display":"none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, [
          _createTextVNode(_toDisplayString(_ctx.constants.GET_IN_TOUCH_TEXT) + " ", 1),
          _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.constants.HAVE_A_QUESTION_TEXT), 1)
        ]),
        _createElementVNode("button", {
          class: "close-pop-btn",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetform()))
        }),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", {
            onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["input-field", { error: _ctx.titleError && !_ctx.Name }])
            }, [
              _createElementVNode("label", null, _toDisplayString(_ctx.constants.NAME_LABEL), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.Name) = $event)),
                placeholder: "Enter your name"
              }, null, 512), [
                [_vModelText, _ctx.Name]
              ]),
              (_ctx.titleError && !_ctx.Name)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.constants.THIS_FIELD_IS_REQUIRED), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["input-field", { error: (_ctx.titleError && !_ctx.Email) || (_ctx.emailError.length) }])
            }, [
              _createTextVNode(_toDisplayString(_ctx.constants.EMAIL_LABEL) + " ", 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.Email) = $event)),
                placeholder: "Enter your email",
                onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateEmail(_ctx.nextClicked)))
              }, null, 544), [
                [_vModelText, _ctx.Email]
              ]),
              (_ctx.titleError && !_ctx.Email)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.constants.THIS_FIELD_IS_REQUIRED), 1))
                : _createCommentVNode("", true),
              (!(_ctx.titleError && !_ctx.Email))
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.emailError), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["input-field", { error: _ctx.titleError && !_ctx.number }])
            }, [
              _createTextVNode(_toDisplayString(_ctx.constants.PHONE_NUMBER_LABEL) + " ", 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.number) = $event)),
                placeholder: "Enter your Phone Number",
                onKeypress: _cache[5] || (_cache[5] = ($event: any) => (_ctx.preventNonNumeric($event)))
              }, null, 544), [
                [_vModelText, _ctx.number]
              ]),
              (_ctx.titleError && !_ctx.number)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.constants.THIS_FIELD_IS_REQUIRED), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", _hoisted_11, [
                _createTextVNode(_toDisplayString(_ctx.constants.MESSAGE_LABEL), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.constants.OPTIONAL_LABEL), 1)
              ]),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.Message) = $event)),
                placeholder: "Enter your message"
              }, null, 512), [
                [_vModelText, _ctx.Message]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("button", {
                  type: "button",
                  class: "secondary-outline-btn",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.resetform())),
                  "data-bs-dismiss": "modal"
                }, _toDisplayString(_ctx.constants.CANCEL_BUTTON), 1),
                _createElementVNode("button", _hoisted_14, _toDisplayString(_ctx.constants.SUBMIT_BUTTON), 1),
                _createElementVNode("button", _hoisted_15, null, 512)
              ])
            ])
          ], 32)
        ])
      ])
    ])
  ]))
}