<template>
  <div :class="srcPanelClass">
    <!-- Default image -->
    <img v-show="!src || !isImageLoaded" :src="defaultImage || '/assets/images/avatars/avatar-pic.png'"
      class="ui placeholder" :class="defaultImagePanelClass" alt="default image" />

    <!-- Source image -->
    <img v-show="src && isImageLoaded" :src="cdnImageAddress" :class="srcPanelClass" @load="showLoadedImage"
      alt="cdn image" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { environment } from '@/config/environment';

export default defineComponent({
  name: 'CdnImageLoader',
  props: {
    defaultImage: String,
    defaultImagePanelClass: String,
    height: Number,
    isCdnImage: {
      type: Boolean,
      default: true
    },
    src: String,
    srcPanelClass: String,
    width: Number
  },
  setup(props, { emit }) {
    const isImageLoaded = ref(false);
    const cdnImageAddress = ref('');

    watch(() => props.src, (newSrc) => {
      if (newSrc && props.isCdnImage) {
        cdnImageAddress.value = getCdnImageAddress(newSrc);
        isImageLoaded.value = false;
      }
    }, { immediate: true });

    function getCdnImageAddress(src: string): string {
      if (!src) return '';
      let cdnUrl: string = environment.imageCdn.getHost;
      if (props.width) {
        cdnUrl = cdnUrl.concat('w_' + props.width + ',');
      }
      if (props.height) {
        cdnUrl = cdnUrl.concat('h_' + props.height);
      }
      return cdnUrl + '/' + encodeURIComponent(environment.ImageBase + '/' + src);
    }

    function showLoadedImage() {
      isImageLoaded.value = true;
      emit('saveImage');
    }

    return { isImageLoaded, cdnImageAddress, showLoadedImage };
  }
});
</script>
