import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "mainContainer" }
const _hoisted_2 = { class: "admin-mid-container" }
const _hoisted_3 = { class: "custom-row admin-page-row" }
const _hoisted_4 = { class: "right-sidebar" }
const _hoisted_5 = { class: "right-sidebar-in" }
const _hoisted_6 = { class: "form-content" }
const _hoisted_7 = { class: "input-field input-width-95" }
const _hoisted_8 = { class: "input-field input-width-95" }
const _hoisted_9 = { class: "input-field-submit" }
const _hoisted_10 = { class: "button-center" }
const _hoisted_11 = { class: "secondary-outline-btn" }
const _hoisted_12 = {
  class: "primary-outline-btn",
  type: "submit"
}
const _hoisted_13 = { class: "admin-site-footer" }
const _hoisted_14 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_LeftSidebarComponent = _resolveComponent("LeftSidebarComponent")!
  const _component_ckeditor = _resolveComponent("ckeditor")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_HeaderComponent),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LeftSidebarComponent, { activeIcon: _ctx.activeIcon }, null, 8, ["activeIcon"]),
        _createElementVNode("section", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.constants.WHAT_WILL_YOU_GAIN_TEXT), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("form", {
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.constants.TITLE_LABEL), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: "Enter the text here",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.benefits.title) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.benefits.title]
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.constants.DESCRIPTION_LABEL), 1),
                  _createVNode(_component_ckeditor, {
                    placeholder: "Description",
                    class: "text-editer",
                    editor: _ctx.editor,
                    modelValue: _ctx.benefits.description,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.benefits.description) = $event)),
                    config: _ctx.editorConfig
                  }, null, 8, ["editor", "modelValue", "config"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.constants.CANCEL_BUTTON), 1),
                    _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.constants.SUBMIT_BUTTON), 1)
                  ])
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("footer", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.constants.COPYRIGHT_TEXT), 1)
    ])
  ]))
}