<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <div class="heading-buttons">
                            <h1>

                                {{ constants.NEWSLETTER_SUBSCRIPTION_REQUEST_TEXT }}

                            </h1>
                            <div class="heading-buttons-set">
                                <button class="delete-item txt-btn" @click="deleteSelectedSubscription">

                                    {{ constants.DELETE_BUTTON }}

                                </button>
                            </div>
                        </div>
                        <div class="newsletter-table" data-simplebar>
                            <div class="table-view newsletter">
                                <div class="table-view-top">
                                    <div class="table-row">
                                        <div class="checkbox-item pd-2">
                                            <div class="checkbox">
                                                <input name="cssCheckbox" id="selectAll" type="checkbox"
                                                    class="css-checkbox" @change="toggleSelectAll"
                                                    :checked="selectedSubscription.length === newsletterSubscribed.length && newsletterSubscribed.length > 0">
                                                <label for="selectAll"></label>
                                            </div>
                                        </div>

                                        <div class="email-label-item pd-1">

                                            {{ constants.EMAIL_LABEL }}

                                        </div>

                                        <div class="requested-date-label-item pd-1">

                                            {{ constants.REQUESTED_DATE_LABEL }}

                                        </div>

                                    </div>
                                </div>
                                <div class="table-view-btm table-scroll">
                                    <div class="table-row" v-for="subscriber in newsletterSubscribed"
                                        :key="subscriber.id">
                                        <div class="checkbox-item pd-2">
                                            <div class="checkbox">
                                                <input name="cssCheckbox" :id="'demo_opt_' + subscriber.id"
                                                    type="checkbox" class="css-checkbox"
                                                    @change="toggleSubscriptionSelection(subscriber.id)"
                                                    :checked="selectedSubscription.includes(subscriber.id)">
                                                <label :for="'demo_opt_' + subscriber.id"></label>
                                            </div>
                                        </div>

                                        <div class="email-label-item pd-1" :title="subscriber.Email">{{ subscriber.Email
                                            }}</div>

                                        <div class="requested-date-label-item pd-1">
                                            {{ formatDate(subscriber.ReqDate) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pagination-box">
                            <div class="pagination-box-in">
                                <ul>
                                    <li v-if="currentPage !== 1" class="pre-pagination"
                                        @click="changePage(currentPage - 1)">
                                        <a href="#"></a>
                                    </li>
                                    <li v-for="page in visiblePages" :key="page" class="num-pagination"
                                        @click="changePage(page)" :class="{ active: currentPage === page }">
                                        <a href="#">{{ page }}</a>
                                    </li>
                                    <li v-if="currentPage !== totalPages" class="next-pagination"
                                        @click="changePage(currentPage + 1)">
                                        <a href="#"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">

                {{ constants.COPYRIGHT_TEXT }}

            </div>
        </footer>
    </section>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import constants from '@/utils/en-US-Admin.json';
import axios from 'axios';
import customJSFunction from "@/assets/js/custom.js";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import HeaderComponent from "@/components/HeaderComponent.vue";


interface newsletterSubscribed {
    id: number;
    Email: string;
    ReqDate: string;
}

export default defineComponent({
    name: 'NewsletterSubscribed',
    setup() {
        const activeIcon = ref('adminNewsletter');

        return {
            activeIcon,
        };
    },
    data() {
        return {
            constants: constants,
            currentPage: 1,
            totalPages: 1,
            newsletterSubscribed: [] as newsletterSubscribed[],
            selectedSubscription: [] as number[]
        }
    },
    components: {
        HeaderComponent,
        LeftSidebarComponent
    },
    computed: {
        visiblePages() {
            const maxVisiblePages = 5; // Maximum number of page numbers to show
            const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
            let start = Math.max(this.currentPage - halfMaxVisiblePages, 1);
            let end = start + maxVisiblePages - 1;

            if (end > this.totalPages) {
                end = this.totalPages;
                start = Math.max(end - maxVisiblePages + 1, 1);
            }

            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        }
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    created() {
        this.fetchSubscribedEmails();
    },
    methods: {
        formatDate(dateString: any) {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${day}/${month}/${year}`;
        },
        async fetchSubscribedEmails() {
            try {
                const limit = 10;
                const skip = (this.currentPage - 1) * limit;
                const response = await axios.post(`${API_BASE_URL}/newsSubscribe/`, { limit, skip });
                this.newsletterSubscribed = response.data.data;
                this.totalPages = Math.ceil(response.data.total_count / limit);
            } catch (error) {
                console.error(error);
            }
        },
        changePage(page: number) {
            if (page > 0 && page <= this.totalPages) {
                this.currentPage = page;
                this.fetchSubscribedEmails();
            }
        },
        toggleSubscriptionSelection(subscriptionId: number) {
            if (this.selectedSubscription.includes(subscriptionId)) {
                this.selectedSubscription = this.selectedSubscription.filter(id => id !== subscriptionId);
            } else {
                this.selectedSubscription.push(subscriptionId);
            }
        },
        toggleSelectAll() {
            if (this.selectedSubscription.length === this.newsletterSubscribed.length) {
                this.selectedSubscription = [];
            } else {
                this.selectedSubscription = this.newsletterSubscribed.map(newsletterSubscribed => newsletterSubscribed.id);
            }
        },
        async deleteSelectedSubscription() {
            if (this.selectedSubscription.length === 0) {
                alert('Please select subscription to delete');
                return;
            }
            try {
                await axios.put(`${API_BASE_URL}/newsSubscribe/update/`, { newssubscriptionIds: this.selectedSubscription });
                this.newsletterSubscribed = this.newsletterSubscribed.filter(newsLetter => !this.selectedSubscription.includes(newsLetter.id));
                this.selectedSubscription = [];
                this.fetchSubscribedEmails();
                if (this.newsletterSubscribed.length === 0) {
                    this.currentPage = this.currentPage - 1;
                    if (this.currentPage < 1) {
                        this.currentPage = 1;
                    }
                    this.fetchSubscribedEmails();
                }
            } catch (error) {
                console.error(error);
            }
        },
    }
});
</script>