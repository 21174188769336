<template>
    <!-- ourMission Pop Component -->
    <div class="modal fade big-pop our-mission-pop" id="ourMission" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="pop-row">
                    <div class="pop-row-left">
                        <BCarousel v-if="aboutUsData.images.length > 0" class="carousel slide"
                            id="carouselExampleControlsA" controls :interval="2000">
                            <BCarouselSlide v-for="(image, index) in aboutUsData.images" :key="image.id"
                                :style="{ 'background-image': `url(${getCdnImageAddress(image.image, `w_476,h_733/`)})` }"
                                active>
                            </BCarouselSlide>
                            <template #controls="{ onPrev, onNext }">
                                <a class="carousel-control-prev" href="#" role="button" @click.prevent="onPrev">
                                    <img src="../assets/img/yellow-arrow-left.svg" alt="" />
                                </a>
                                <a class="carousel-control-next" href="#" role="button" @click.prevent="onNext">
                                    <img src="../assets/img/yellow-arrow-right.svg" alt="" />
                                </a>
                            </template>
                        </BCarousel>
                    </div>
                    <div class="pop-row-right">
                        <button class="close-pop-btn" data-bs-dismiss="modal" aria-label="Close"></button>
                        <h2>{{ aboutUsData.title }}</h2>
                        <div class="our-mission-pop-content pop-scroll-height" ref="scrollWrapper" data-bs-simplebar>
                            <div class="des">
                                <p><strong>{{ aboutUsData.brief_description }}</strong></p>
                                <p v-html="aboutUsData.description"></p>
                            </div>
                        </div>
                        <div class="view-profile ">
                            <strong>{{ constants.VIEW_MY_PROFILE_ON_TEXT }}</strong>
                            <div v-for="(media, index) in aboutUsData.social_media" :key="index">
                                <a :href="media.url" target="_blank">
                                    <img :src="require(`../assets/img/${media.name}-icon.svg`)"
                                        :alt="`${media.name} icon`">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import constants from '@/utils/en-US-User.json';
import axios from 'axios';
import SimpleBar from 'simplebar';

import { BCarousel, BCarouselSlide } from 'bootstrap-vue-3';
import { environment } from '@/config/environment';

import { API_BASE_URL } from '@/config/api.config';


interface AboutUs {
    [key: string]: any;
    title: string;
    brief_description: string;
    description: string;
    social_media: Array<{ name: string; url: string }>;
    images: Array<{ id: number, image: string }>;
}

export default defineComponent({
    name: 'AboutMeComponent',
    components: {
        BCarousel,
        BCarouselSlide
    },
    data() {
        return {
            constants: constants,
            aboutUsData: {
                title: '',
                brief_description: '',
                description: '',
                social_media: [],
                images: []
            } as AboutUs
        }
    },
    mounted() {

        this.fetchAboutMeData();

        const el = this.$refs.scrollWrapper as HTMLElement;
        if (el) {
            new SimpleBar(el);
        }


    },
    methods: {
        async fetchAboutMeData() {
            try {
                const response = await axios.get(`${API_BASE_URL}/aboutus/`);
                if (response.data.length > 0) {
                    this.aboutUsData = response.data[0];
                    this.fetchImages();
                } else {
                }
            } catch (error) {
                console.error(error);
            }
        },
        async fetchImages() {
            try {
                const response = await axios.get(`${API_BASE_URL}/images/`);
                if (response.data.length > 0) {
                    this.aboutUsData.images = response.data;
                } else {
                }
            } catch (error) {
                console.error(error);
            }
        },
        getCdnImageAddress(src: string, size: string): string {
            if (!src) return '';
            let cdnUrl: string = environment.imageCdn.getHost;
            cdnUrl = cdnUrl.concat(size);
            return cdnUrl + encodeURIComponent(src);
        },
    }

});
</script>