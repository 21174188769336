<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <h1>
                            {{ constants.BENEFITS_OF_SUBSCRIPTION_POP_UP_MENU }}
                        </h1>
                        <div class="form-content">
                            <form @submit.prevent="submitForm">
                                <div class="input-field input-width-95">

                                    <label>{{ constants.TITLE_LABEL }}</label>

                                    <input type="text" placeholder="Enter the text here" v-model="benefits.title" />
                                </div>

                                <div class="input-field input-width-95">

                                    <label> {{ constants.DESCRIPTION_LABEL }}</label>

                                    <ckeditor placeholder="Description" class="text-editer" :editor="editor"
                                        v-model="benefits.description" :config="editorConfig">
                                    </ckeditor>
                                    <!-- <textarea placeholder="Description" v-model="benefits.description"></textarea> -->

                                </div>
                                <div class="input-field ">

                                    <label> {{ constants.UPLOAD_IMAGE_LABEL }}</label>

                                    <div class="multiple-image-upload-row">
                                        <div class="multiple-image-box multiple-image-upload"
                                            v-for="(image, index) in benefits.images" :key="index">
                                            <button type="button" class="Uploaded-img-delete"
                                                @click="deleteImage(image.id)"></button>
                                            <div class="multiple-img-uploaded-img">
                                                <img :src="image.image" alt="">
                                            </div>
                                        </div>
                                        <div class="multiple-image-box" v-for="(src, index) in imageSrc"
                                            :key="'image' + index">
                                            <img :src="src" alt="Preview"
                                                style="max-width: 100%; max-height: 100%; display: block;" />
                                            <button type="button" class="Uploaded-img-delete"
                                                @click="deleteImageFromSrc(index)"></button>
                                        </div>
                                        <div class="multiple-image-box add-multiple-image">
                                            <div class="multiple-img-upload-icon">
                                                <i>
                                                    <img src="../../assets/img/add-img-icon.svg" alt="">
                                                </i>
                                                <span>
                                                    {{ constants.ADD_IMAGE_LABEL }}
                                                </span>
                                            </div>
                                            <div class="multiple-img-upload-input">
                                                <input type="file" accept="image/*" @change="handleFileUpload($event)" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="input-field-submit">
                                    <div class="button-center ">
                                        <button class="secondary-outline-btn">
                                            {{ constants.CANCEL_BUTTON }}
                                        </button>
                                        <button type="submit" class="primary-outline-btn">
                                            {{ constants.SUBMIT_BUTTON }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">
                {{ constants.COPYRIGHT_TEXT }}
            </div>
        </footer>
    </section>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import FooterComponent from "@/components/FooterComponent.vue";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import constants from '@/utils/en-US-Admin.json';
import HeaderComponent from '@/components/HeaderComponent.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface Benefits {
    [key: string]: any;
    id: number | null;
    title: string;
    description: string;
    images: Array<{ id: number, image: string }>;
}

export default defineComponent({
    components: {
        HeaderComponent,
        FooterComponent,
        LeftSidebarComponent,
    },
    data() {
        return {
            constants: constants,
            benefits: {
                id: null,
                title: '',
                description: '',
                images: [],
            } as Benefits,
            selectedFiles: [] as File[],
            imageSrc: [] as string[],
            imagePreviewed: false,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        'insertTable',
                    ]
                },
            }
        }
    },
    setup() {
        const activeIcon = ref('admin-benefits-of-subs');

        return {
            activeIcon,
        };
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    methods: {
        fetchBenefits() {
            axios.get(`${API_BASE_URL}/benefitsOfSub/`)
                .then(response => {
                    if (response.data.length > 0) {
                        this.benefits = response.data[0];
                        this.fetchImages();
                    } else {
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        fetchImages() {
            axios.get(`${API_BASE_URL}/BenefitImages/`)
                .then(response => {
                    if (response.data.length > 0) {
                        this.benefits.images = response.data;
                    } else {
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },

        deleteImageFromSrc(index: number) {
            this.imageSrc.splice(index, 1);
            this.selectedFiles.splice(index, 1);

        },

        handleFileUpload(event: Event) {
            const target = event.target as HTMLInputElement;
            if (target && target.files && target.files.length > 0) {
                // Get the selected file
                const file = target.files[0];

                // Add the selected file to selectedFiles
                this.selectedFiles.push(file);

                // Create a new FileReader to read the file
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    if (e.target && typeof e.target.result === 'string') {
                        this.imageSrc.push(e.target.result);
                        this.imagePreviewed = true;
                    }
                }
            }
        },

        uploadImage(file: File) {
            const formData = new FormData();
            formData.append('image', file);

            for (let [key, value] of formData.entries()) {
            }

            axios.post(`${API_BASE_URL}/BenefitImages/`, formData)
                .then(response => {
                    // this.fetchBenefits();
                    this.imagePreviewed = false;
this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)
                    // this.selectedFiles = [];
                    // this.imageSrc = [];
                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitForm() {
            const formData = new FormData();
            formData.append('title', this.benefits.title);
            formData.append('description', this.benefits.description);
            for (let i = 0; i < this.selectedFiles.length; i++) {
                this.uploadImage(this.selectedFiles[i]);
            }

            if (this.benefits.id) {
                // If the benefits data has an id, update the existing record
                axios.put(`${API_BASE_URL}/benefitsOfSub/${this.benefits.id}/`, formData)
                    .then(response => {
                        this.fetchBenefits();
                        this.imagePreviewed = false;
                        this.selectedFiles = [];
                        this.imageSrc = [];
this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                console.log('formData', formData);
                // If the benefits data does not have an id, create a new record
                axios.post(`${API_BASE_URL}/benefitsOfSub/`, formData)
                    .then(response => {
                        this.fetchBenefits();
                        this.imagePreviewed = false;
                        this.selectedFiles = [];
                        this.imageSrc = [];
this.$store.commit('showSubmitSuccess')
                this.$store.commit('showSubmit')
                setTimeout(() => {
                    this.$store.commit('hideSubmitSuccess')
                }, 10000)
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },
        deleteImage(imageId: number) {
            axios.delete(`${API_BASE_URL}/BenefitImages/${imageId}/`)
                .then(response => {
                    // Remove the image from the local state if necessary
                    this.benefits.images = this.benefits.images.filter(image => image.id !== imageId);
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
    mounted() {
        this.fetchBenefits();
    },
});
</script>
