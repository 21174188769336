<template>
    <div class="show-sidebar">
        <button id="showMenu"></button>
    </div>
    <section class="left-sidebar" id="simplebar" ref="scrollWrapper" data-simplebar>
        <div class="left-sidebar-in">
            <div class="hide-sidebar">
                <button id="hideMenu">
                    {{ constants.HIDE_MENU_BUTTON }}
                </button>
            </div>
            <h3>{{ constants.STATIC_CONTENT_TEXT }}</h3>
            <ul>
                <li>
                <li><router-link to="/user/home" :class="{ active: activeIcon === 'adminHome' }">{{
                    constants.HOME_PAGE_HERO_MENU }}</router-link></li>
                </li>
                <li>
                <li><router-link to="/user/aboutUs" :class="{ active: activeIcon === 'aboutUs' }">{{
                    constants.ABOUT_ME_MENU }}</router-link></li>
                </li>
                <li>
                <li><router-link to="/user/services" :class="{ active: activeIcon === 'services' }">{{
                    constants.SERVICES_MENU }}</router-link></li>
                </li>
                <li :class="{ 'submenu': !show, 'submenu-show submenu': show }" id="subMenuOne">
                    <a id="clickSubMenuOne" @click="toggleShow">
                        {{ constants.BENEFITS_MENU }}
                    </a>
                    <ul>
                        <li>
                            <router-link to="/user/benefitsToGain"
                                :class="{ active: activeIcon === 'admin-benefits-to-gain' }">{{
                                    constants.WHAT_WILL_YOU_GAIN_MENU }}</router-link>
                        </li>
                        <li>
                            <router-link to="/user/benefitsOfSubs"
                                :class="{ active: activeIcon === 'admin-benefits-of-subs' }">{{
                                    constants.BENEFITS_OF_SUBSCRIPTION_POP_UP_MENU }}</router-link>

                        </li>
                    </ul>
                </li>
                <li>
                    <router-link to="/user/blog" :class="{ active: activeIcon === 'adminBlog' }">{{
                        constants.BLOGS_MENU }}</router-link>
                </li>
            </ul>
            <h3>{{ constants.USER_REQUESTS_TEXT }}</h3>
            <ul>
                <li>
                    <router-link to="/user/appointment" :class="{ active: activeIcon === 'adminBooking' }">
                        {{ constants.BOOKING_APPOINTMENTS_MENU }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/user/contactUs" :class="{ active: activeIcon === 'adminContact' }">
                        {{ constants.CONTACT_US_MENU }}</router-link>
                </li>
                <!-- <li>
                <router-link to="/user/newsletter" :class="{active:activeIcon==='adminNewsletter'}">
                    {{ constants.NEWSLETTER_SUBSCRIPTION_REQUEST_MENU }}</router-link>
             
            </li> -->
                <li>
                    <router-link to="/user/payments" :class="{ active: activeIcon === 'adminPayments' }">
                        {{ constants.ALL_OTHER_PAYMENTS_MENU }}</router-link>

                </li>
            </ul>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import adminConstants from '@/utils/en-US-Admin.json';
import SimpleBar from 'simplebar';
import router from '@/router';
import customJSFunction from "@/assets/js/custom.js";



export default defineComponent({
    name: 'AdminLeftSideBarComponent',
    props: {
        activeIcon: String
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        toggleShow() {
            this.show = !this.show;
        },
    },
    setup(props, { emit }) {
        const setActive = (icon: string) => {
            emit('update:activeIcon', icon);
        };

        const toggleActive = (icon: string) => {
            emit('update:activeIcon', icon === props.activeIcon ? '' : icon);
        };
        const constants = adminConstants;
        return {
            constants,
            setActive,
            toggleActive
        };
    },
    mounted() {
        customJSFunction();

        if (this.activeIcon === 'admin-benefits-to-gain' || this.activeIcon === 'admin-benefits-of-subs') {
            this.show = true;
        }

        const el = this.$refs.scrollWrapper as HTMLElement;
        if (el) {
            new SimpleBar(el);
        }
    },
});
</script>
