<template>
    <section id="mainContainer" :class="{ 'mobile_nav_class': isActive }">
        <HeaderComponent :activeIcon.sync="activeIcon"></HeaderComponent>

        <section class="mid-container">
            <section class="page-hero-section"
                :style="{ 'background-image': `url(${require('../../assets/img/dummy/blog-banner-img.jpg')})` }">
                <div class="container">
                    <h1>{{ constants.BLOG_TEXT }}</h1>
                </div>
            </section>
            <section class="blog-list-section">
                <div class="container">
                    <div class="blog-list-item" v-for="blog in BlogData" :key="blog.id" data-aos="fade-up">
                        <div class="blog-list-item-info">
                            <div class="blog-list-month">{{ getMonthName(blog.publishedDate) }}</div>
                            <div class="blog-list-date">{{ getDate(blog.publishedDate) }}</div>
                            <span class="blog-list-year">{{ getYear(blog.publishedDate) }}</span>
                        </div>
                        <div class="blog-list-row">
                            <div class="blog-list-img">
                                <img :src='getCdnImageAddress(blog.BlogImage, `w_510,h_420/`)' alt="Alt text here" />
                            </div>
                            <div class="blog-list-content">
                                <h3>{{ trimText(blog.title, 110) }}</h3>
                                <div class="des">
                                    <p>{{ trimText(blog.short_description, 445) }}</p>
                                </div>
                                <div class="read-more">
                                    <router-link :to="`/BlogReadMore`" @click="saveId(blog.id)">{{
                                        constants.READ_MORE_LINK }}</router-link>
                                    <!-- <a href="/BlogReadMore">Read More.</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </section>
        <FooterComponent></FooterComponent>
    </section>

    <!-- ourMission Pop HTML Start -->
    <AboutMeComponent></AboutMeComponent>
    <!-- ourMission Pop HTML End -->

    <ContactUsComponent></ContactUsComponent>
    <!-- getTouch Pop HTML End -->


</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import AboutMeComponent from "@/components/AboutMeComponent.vue";
import ContactUsComponent from '@/components/ContactUsComponent.vue';
import customJSFunction from "@/assets/js/custom.js"
import constants from '@/utils/en-US-User.json';
import { API_BASE_URL } from '@/config/api.config';
import axios from 'axios';
import store from '@/store';
import { environment } from '@/config/environment';

interface Blog {
    id: number;
    title: string;
    short_description: string;
    description: string;
    BlogImage: string;
    publishedDate: string;
}
export default defineComponent({
    data() {
        return {
            constants,
            BlogData: [] as Blog[],
        }
    },
    mounted() {
        customJSFunction();
    },
    setup() {
        const activeIcon = ref('blog'); // Initially set the active icon to 'services'
        const isActive = computed(() => store.state.isActive);

        return {
            activeIcon,
            isActive
        };
    },
    components: {
        HeaderComponent,
        FooterComponent,
        AboutMeComponent,
        ContactUsComponent
    },
    created() {
        this.fetchBlogs();
    },
    methods: {
        getCdnImageAddress(src: string, size: string): string {
            if (!src) return '';
            let cdnUrl: string = environment.imageCdn.getHost;
            cdnUrl = cdnUrl.concat(size);
            return cdnUrl + encodeURIComponent(src);
        },
        saveId(id: number) {
            localStorage.setItem('blogId', id.toString());
        },

        async fetchBlogs() {
            try {
                const response = await axios.get(`${API_BASE_URL}/blogs/`);
                this.BlogData = response.data
                    .filter((blog: { status: number; }) => blog.status === 1)
                    .sort((a: { publishedDate: string }, b: { publishedDate: string }) => {
                        const dateA = new Date(a.publishedDate);
                        const dateB = new Date(b.publishedDate);
                        return dateB.getTime() - dateA.getTime();
                    });
            } catch (error) {
                console.error(error);
            }
        },
        getMonthName(dateString: string) {
            const date = new Date(dateString);
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            return monthNames[date.getMonth()];
        },
        getDate(dateString: string) {
            const date = new Date(dateString);
            return date.getDate();
        },
        getYear(dateString: string) {
            const date = new Date(dateString);
            return date.getFullYear();

        },
        trimText(text: string, length: number) {
            if (text.length > length) {
                return text.substring(0, length) + '...';
            }
            return text;
        }

    }

});
</script>