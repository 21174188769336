<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <div class="heading-buttons">
                            <h1>

                                {{ constants.PAYMENTS_TEXT }}
                            </h1>
                            <div class="heading-buttons-set">
                                <div class="qr-code-set">
                                    <div class="qr-code-set-img" v-if="mostRecentQRImageUrl">
                                        <img :src="mostRecentQRImageUrl" />
                                    </div>
                                    <button class="txt-btn" v-if="!mostRecentQRImageUrl" data-bs-toggle="modal"
                                        data-bs-target="#addQrCode">
                                        {{ constants.ADD_QR_CODE_BUTTON }}
                                    </button>
                                    <button class="txt-btn" v-else data-bs-toggle="modal" data-bs-target="#addQrCode">
                                        {{ constants.CHANGE_QR_CODE_BUTTON }}
                                    </button>
                                </div>
                                <button class="txt-btn " :class="{ 'btn-disabled': !isAnyPaymentSelected }"
                                    :disabled="!isAnyPaymentSelected" @click="deleteSelectedPayment">
                                    {{ constants.DELETE_BUTTON }}
                                </button>
                            </div>

                        </div>

                        <div class=" payment-table" data-simplebar>
                            <div class="table-view payment">
                                <div class="table-view-top">
                                    <div class="table-row">
                                        <div class="checkbox-item pd-2">
                                            <div class="checkbox">
                                                <input name="cssCheckbox" id="selectAll" type="checkbox"
                                                    class="css-checkbox" @change="toggleSelectAll"
                                                    :checked="selectedPayment.length === paymentList.length && paymentList.length > 0">
                                                <label for="selectAll"></label>
                                            </div>
                                        </div>
                                        <div class="payment-name-item pd-1">

                                            {{ constants.NAME_LABEL }}
                                        </div>
                                        <div class="payment-email-item pd-1">

                                            {{ constants.EMAIL_LABEL }}
                                        </div>
                                        <div class="payment-phone-item pd-1">

                                            {{ constants.PHONE_NUMBER_LABEL }}
                                        </div>
                                        <div class="payment-amount-item pd-1">

                                            {{ constants.AMOUNT_LABEL }}
                                        </div>
                                        <div class="payment-purpose-item pd-1">

                                            {{ constants.PURPOSE_OF_PAYMENT_LABEL }}
                                        </div>
                                        <div class="payment-comments-item pd-1">
                                            {{ constants.COMMENTS_LABEL }}
                                        </div>
                                        <div class="payment-tran-item pd-1">
                                            {{ constants.TRANSACTION_ID_LABEL }}
                                        </div>
                                        <div class="record-generated-item pd-1">
                                            {{ constants.RECORD_GENERATED_LABEL }}
                                        </div>

                                    </div>
                                </div>
                                <div class="table-view-btm table-scroll">
                                    <div class="table-row" v-for="payments in paymentList" :key="payments.id">
                                        <div class="checkbox-item pd-2">
                                            <div class="checkbox">
                                                <input name="cssCheckbox" :id="'demo_opt_' + payments.id"
                                                    type="checkbox" class="css-checkbox"
                                                    @change="togglePaymentSelection(payments.id)"
                                                    :checked="selectedPayment.includes(payments.id)">
                                                <label :for="'demo_opt_' + payments.id"></label>
                                            </div>
                                        </div>
                                        <div class="payment-name-item pd-1">{{ payments.FirstName }} {{
                                            payments.LastName }}</div>
                                        <div class="payment-email-item pd-1" :title="payments.Email">{{ payments.Email
                                            }}</div>
                                        <div class="payment-phone-item pd-1">{{ payments.Number }}</div>
                                        <div class="payment-amount-item pd-1">{{ payments.Amount }}</div>
                                        <div class="payment-purpose-item pd-1 full-des">
                                            <span>{{ payments.Purpose }}</span>
                                            <div class="full-des-box">
                                                <div class="full-des-box-in">
                                                    <div class="full-des-icon"></div>
                                                    <div class="full-des-content">
                                                        <div class="full-des-content-in">
                                                            <p>
                                                                {{ payments.Purpose }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="payment-comments-item pd-1 full-des">
                                            <span>
                                                {{ payments.Comments }}
                                            </span>
                                            <div class="full-des-box">
                                                <div class="full-des-box-in">
                                                    <div class="full-des-icon"></div>
                                                    <div class="full-des-content">
                                                        <div class="full-des-content-in">
                                                            <p>
                                                                {{ payments.Comments }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="payment-tran-item pd-1" :title="payments.TransactionID">
                                            {{ payments.TransactionID }}</div>
                                        <div class="record-generated-item pd-1">
                                            {{ formatDate(payments.Updated_at) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pagination-box">
                            <div class="pagination-box-in">
                                <ul>
                                    <li v-if="currentPage !== 1" class="pre-pagination"
                                        @click="changePage(currentPage - 1)">
                                        <a href="#"></a>
                                    </li>
                                    <li v-for="page in visiblePages" :key="page" class="num-pagination"
                                        @click="changePage(page)" :class="{ active: currentPage === page }">
                                        <a href="#">{{ page }}</a>
                                    </li>
                                    <li v-if="currentPage !== totalPages" class="next-pagination"
                                        @click="changePage(currentPage + 1)">
                                        <a href="#"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">

                {{ constants.COPYRIGHT_TEXT }}

            </div>
        </footer>
    </section>
    <div class="modal fade small-pop add-qr-code-pop" id="addQrCode" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content add-qr-code-pop-content">
                <button class="close-pop-btn" data-bs-dismiss="modal" aria-label="Close" @click="reset"></button>
                <h3 v-if="!mostRecentQRImageUrl">{{ constants.ADD_QR_CODE_TEXT }}</h3>
                <h3 v-if="mostRecentQRImageUrl">{{ constants.CHANGE_QR_CODE_BUTTON }}</h3>
                <div class="add-qr-code-box">
                    <div class="drag-drop-box" @dragover.prevent @drop="handleDrop">
                        <div class="selected-image" v-if="selectedFile !== null">
                            <img :src="imageSrc" style="width: 100%;" alt="">
                        </div>
                        <div v-else-if="mostRecentQRImageUrl" class="selected-image">
                            <img :src="mostRecentQRImageUrl" style="width: 100%;" alt="Most Recent QR Image" />
                        </div>
                        <div v-else class="drag-drop">
                            <div class="drag-drop-icon">
                                <img src="../../assets/img/drag-drop-icon.svg" />
                            </div>
                        </div>
                        <div class="drag-drop-label">
                            {{ constants.DRAG_DROP_YOUR_QR_CODE_IMAGE_LABEL }}
                        </div>
                    </div>
                    <div class="or-link"><span>
                            {{ constants.OR_TEXT }}</span></div>
                    <div class="browse-file-box" v-if="selectedFile === null">
                        <div class="browse-file-btn">
                            <input type="file" @change="handleFileUpload" accept="image/*" />
                            <button class="primary-outline-btn">
                                {{ constants.BROWSE_FILE_BUTTON }}</button>
                        </div>
                    </div>
                    <div class="input-field-submit" v-else >
                    <div class="button-center ">
                            <button type="button" class="primary-outline-btn" @click="postQRImage" data-bs-dismiss="modal">
                                {{ constants.SUBMIT_BUTTON }}
                             </button>
                            <button type="button" class="secondary-outline-btn" data-bs-dismiss="modal" @click="reset">
                                {{ constants.CANCEL_BUTTON }}
                            </button>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import constants from '@/utils/en-US-Admin.json';
import axios from 'axios';
import customJSFunction from "@/assets/js/custom.js";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import HeaderComponent from "@/components/HeaderComponent.vue";
import moment from 'moment';

interface PaymentDetailSchema {
    id: number;
    FirstName: string;
    LastName: string;
    Number: string;
    Email: string;
    Amount: string;
    Purpose: string;
    TransactionID: string;
    Comments: string;
    Created_at: string;
    Updated_at: string;
}
interface QRPhotoSchema {
    id: number;
    QRimage: string;
}

export default defineComponent({
    name: 'PaymentDetail',


    setup() {
        const activeIcon = ref('adminPayments');
        const selectedPayment = ref([] as number[]);

        const isAnyPaymentSelected = computed(() => selectedPayment.value.length > 0);

        return {
            activeIcon,
            selectedPayment,
            isAnyPaymentSelected,
        };
    },

    data() {
        return {
            selectedFile: null as File | null,
            QRPhoto: [] as QRPhotoSchema[],
            mostRecentQRImageUrl: '' as string | null,
            imageSrc: '',
            constants: constants,
            currentPage: 1,
            totalPages: 1,
            paymentList: [] as PaymentDetailSchema[],
            selectedPayment: [] as number[],
            droppedFile: null as File | null,
        };
    },
    components: {
        HeaderComponent,
        LeftSidebarComponent,
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    computed: {
        visiblePages() {
            const maxVisiblePages = 5;
            const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
            let start = Math.max(this.currentPage - halfMaxVisiblePages, 1);
            let end = start + maxVisiblePages - 1;

            if (end > this.totalPages) {
                end = this.totalPages;
                start = Math.max(end - maxVisiblePages + 1, 1);
            }

            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },
    },
    created() {
        this.fetchPayments();
    },
    methods: {
        async fetchPayments() {
            try {
                const limit = 10;
                const skip = (this.currentPage - 1) * limit;
                const response = await axios.post(`${API_BASE_URL}/payment/`, { limit, skip });
                this.paymentList = response.data.data;
                this.totalPages = Math.ceil(response.data.total_count / limit);
                this.fetchPaymentQR();
            } catch (error) {
                console.error(error);
            }
        },
        formatDate(dateStr: string) {
            let date = moment(dateStr);
            return date.format('DD/MM/YYYY hh:mm a');
        },
        changePage(page: number) {
            if (page > 0 && page <= this.totalPages) {
                this.currentPage = page;
                this.fetchPayments();
            }
        },
        reset() {
           this.selectedFile = null;
           this.imageSrc = ''; 
        },
        togglePaymentSelection(paymentId: number) {
            if (this.selectedPayment.includes(paymentId)) {
                this.selectedPayment = this.selectedPayment.filter(id => id !== paymentId);
            } else {
                this.selectedPayment.push(paymentId);
            }
        },
        toggleSelectAll() {
            if (this.selectedPayment.length === this.paymentList.length) {
                this.selectedPayment = [];
            } else {
                this.selectedPayment = this.paymentList.map(paymentList => paymentList.id);
            }
        },
        async deleteSelectedPayment() {
            try {
                await axios.put(`${API_BASE_URL}/payment/update/`, { paymentIds: this.selectedPayment });
                this.paymentList = this.paymentList.filter(payment => !this.selectedPayment.includes(payment.id));
                this.selectedPayment = [];
                this.fetchPayments();
                if (this.paymentList.length === 0) {
                    this.currentPage = this.currentPage - 1;
                    if (this.currentPage < 1) {
                        this.currentPage = 1;
                    }
                    this.fetchPayments();
                }
            } catch (error) {
                console.error(error);
            }
        },
        handleFileUpload(event: any) {
            const target = event.target as HTMLInputElement;
            if (target && target.files && target.files.length > 0) {
                this.selectedFile = target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(this.selectedFile);
                reader.onload = (e) => {
                    if (e.target && typeof e.target.result === 'string') {
                        this.imageSrc = e.target.result;
                        // this.postQRImage(this.selectedFile);
                    }
                }
            }
        },
        async postQRImage() {
            try {
                const formData = new FormData();
                if (this.selectedFile instanceof File) {
                    formData.append('QRimage', this.selectedFile);
                }

                const response = await axios.post(`${API_BASE_URL}/paymentQR/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.imageSrc = "";
                this.selectedFile = null;
                this.fetchPaymentQR();
            } catch (error) {
                console.error(error);
            }
        },
        async fetchPaymentQR() {
            try {
                const response = await axios.get(`${API_BASE_URL}/paymentQR/`);
                this.QRPhoto = response.data.sort((a: { id: number }, b: { id: number }) => b.id - a.id);
                if (this.QRPhoto.length > 0) {
                    this.mostRecentQRImageUrl = this.QRPhoto[0].QRimage;
                }
            } catch (error) {
                console.error(error);
            }
        },
        handleDrop(event: any) {
            event.preventDefault();
            this.droppedFile = event.dataTransfer.files[0];
            this.updateImageUrl();
        },
        updateImageUrl() {
            if (this.droppedFile) {
                this.mostRecentQRImageUrl = URL.createObjectURL(this.droppedFile);
                this.selectedFile = this.droppedFile;
                const reader = new FileReader();
                reader.readAsDataURL(this.selectedFile);
                reader.onload = (e) => {
                    if (e.target && typeof e.target.result === 'string') {
                        this.imageSrc = e.target.result;
                        // this.postQRImage(this.selectedFile);
                    }
                }
                // this.postQRImage(this.selectedFile);
            } else {
                this.mostRecentQRImageUrl = null;
            }
        },
    },

})
</script>
