import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/global.css'
import './assets/css/aos.css';
import AOS from 'aos'; 
import './assets/js/custom.js'
import 'bootstrap'
import BootstrapVue3 from 'bootstrap-vue-3'
import CKEditor from '@ckeditor/ckeditor5-vue'


createApp(App).use(store).use(BootstrapVue3).use(CKEditor).use(router).mount('#app')
AOS.init();
