import { vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.srcPanelClass)
  }, [
    _withDirectives(_createElementVNode("img", {
      src: _ctx.defaultImage || '/assets/images/avatars/avatar-pic.png',
      class: _normalizeClass(["ui placeholder", _ctx.defaultImagePanelClass]),
      alt: "default image"
    }, null, 10, _hoisted_1), [
      [_vShow, !_ctx.src || !_ctx.isImageLoaded]
    ]),
    _withDirectives(_createElementVNode("img", {
      src: _ctx.cdnImageAddress,
      class: _normalizeClass(_ctx.srcPanelClass),
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showLoadedImage && _ctx.showLoadedImage(...args))),
      alt: "cdn image"
    }, null, 42, _hoisted_2), [
      [_vShow, _ctx.src && _ctx.isImageLoaded]
    ])
  ], 2))
}