import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mid-container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "blog-list-section" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "blog-list-item-info" }
const _hoisted_6 = { class: "blog-list-month" }
const _hoisted_7 = { class: "blog-list-date" }
const _hoisted_8 = { class: "blog-list-year" }
const _hoisted_9 = { class: "blog-list-row" }
const _hoisted_10 = { class: "blog-list-img" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "blog-list-content" }
const _hoisted_13 = { class: "des" }
const _hoisted_14 = { class: "read-more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_AboutMeComponent = _resolveComponent("AboutMeComponent")!
  const _component_ContactUsComponent = _resolveComponent("ContactUsComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      id: "mainContainer",
      class: _normalizeClass({ 'mobile_nav_class': _ctx.isActive })
    }, [
      _createVNode(_component_HeaderComponent, { activeIcon: _ctx.activeIcon }, null, 8, ["activeIcon"]),
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("section", {
          class: "page-hero-section",
          style: _normalizeStyle({ 'background-image': `url(${require('../../assets/img/dummy/blog-banner-img.jpg')})` })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.constants.BLOG_TEXT), 1)
          ])
        ], 4),
        _createElementVNode("section", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.BlogData, (blog) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "blog-list-item",
                key: blog.id,
                "data-aos": "fade-up"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getMonthName(blog.publishedDate)), 1),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getDate(blog.publishedDate)), 1),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getYear(blog.publishedDate)), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("img", {
                      src: _ctx.getCdnImageAddress(blog.BlogImage, `w_510,h_420/`),
                      alt: "Alt text here"
                    }, null, 8, _hoisted_11)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.trimText(blog.title, 110)), 1),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.trimText(blog.short_description, 445)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_router_link, {
                        to: `/BlogReadMore`,
                        onClick: ($event: any) => (_ctx.saveId(blog.id))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.constants.READ_MORE_LINK), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]),
      _createVNode(_component_FooterComponent)
    ], 2),
    _createVNode(_component_AboutMeComponent),
    _createVNode(_component_ContactUsComponent)
  ], 64))
}