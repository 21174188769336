<template>
    <div id="mainContainer" :class="{ 'mobile_nav_class': isActive }">
        <HeaderComponent :activeIcon.sync="activeIcon"></HeaderComponent>
        <section class="mid-container">
            <section class="hero-slider-section" v-if="slides.length > 0">
                <div class="hero-slider">
                    <div v-for="slide in slides" :key="slide.slide_title" class="hero-slider-item"
                        :style="{ 'background-image': `url(${getCdnImageAddress(slide.slide_image)})` }">
                        <div class="container">
                            <div class="hero-slider-item-content">
                                <h1>{{ slide.slide_title }}</h1>
                                <div class="button-left">
                                    <a href="#" class="primary-outline-btn" data-bs-toggle="modal"
                                        data-bs-target="#appointmentPop">{{
                                            constants.BOOK_AN_APPOINTMENT_TEXT }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about-section" data-aos="fade-up" v-if="aboutUsData">
                <div class="container">
                    <div class="custom-row">
                        <div class="about-section-left">
                            <h2>{{ aboutUsData.title }}</h2>
                            <div class="des">
                                <p>{{ trimText(aboutUsData.brief_description, 330) }}</p>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#ourMission">{{
                                    constants.READ_MORE_BUTTON }}</a>
                            </div>
                        </div>
                        <div class="about-section-right"
                            :style="{ 'background-image': `url(${require('../../assets/img/shap-1.svg')})` }">
                            <img src="../../assets/img/dummy/about-section-img.png" alt="" />
                        </div>
                    </div>
                </div>

            </section>

            <section class="our-services-section" data-aos="fade-up">
                <div class="shadow-bg"></div>
                <div class="container">
                    <div class="main-heading">
                        <h2>{{ serviceMain?.title }}</h2>
                        <p>{{ serviceMain?.sub_content }}</p>
                    </div>
                    <div class="our-services-row">
                        <div class="service-slider" v-if="services.length">
                            <div class="our-services-box" v-for="service in services" :key="service.id">
                                <div class="our-services-box-in">
                                    <h4>{{ trimText(service.title, 45) }}</h4>
                                    <p>{{ trimText(service.short_description, 220) }}</p>
                                    <div class="our-services-box-in-link-box">
                                        <div class="button-center">
                                            <router-link to="/services" class="white-btn">{{ constants.READ_MORE_BUTTON
                                                }}</router-link>
                                            <!-- <a class="white-btn" :href="service.link">{{ constants.READ_MORE_BUTTON }}</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section class="gain-section" data-aos="fade-up" v-if="benefits">
                <div class="small-container">
                    <div class="main-heading">
                        <h2>{{ benefits.title }}</h2>
                    </div>
                    <div class="des">
                        <p v-html="benefits.description"></p>
                    </div>
                </div>

            </section>

            <section class="blog-slider-section" data-aos="fade-up" v-if="BlogData.length">
                <div class="container">
                    <div class="main-heading">
                        <h2>Blog</h2>
                    </div>
                    <div class="blog-slider" v-if="BlogData.length">
                        <div class="blog-slider-item" v-for="blog in BlogData" :key="blog.id">
                            <div class="des">
                                <h5>{{ blog.title }}</h5>
                                <p>{{ trimText(blog.short_description, 270) }}</p>
                            </div>
                            <div class="button-center">
                                <router-link class="white-btn" :to="`/BlogReadMore`" @click="saveId(blog.id)">{{

                                    constants.READ_MORE_LINK }}</router-link>
                                <!-- <a class="white-btn" :href="'blog-details.html?id=' + blog.id">Read More</a> -->
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section class="newsletter-section" data-aos="fade-up">
                <div class="container">
                    <div class="newsletter-box">
                        <div class="main-heading">
                            <h2>{{ constants.WEEKLY_NEWSLETTER_TEXT }}</h2>
                            <p>{{ constants.WEEKLY_NEWSLETTER_SUB_TEXT }}</p>
                        </div>
                        <div class="benefits-of-subscription-box">
                            <h3>{{ constants.PER_MONTH }}</h3>
                            <button class="benefits-pop-btn" data-bs-toggle="modal"
                                data-bs-target="#benefitsSubscription">{{ constants.BENEFITS_OF_SUBSCRIPTION_TEXT }}</button>
                            <div class="newsletter-form">
                                <form @submit.prevent="subscribe">
                                    <div class="custom-row"
                                        :class="{ error: (titleError && !email) || (emailError.length) }">
                                        <input type="text" placeholder="Enter you email address" v-model="email"
                                            @input="validateEmail(nextClicked)" />
                                        <button type="submit"
                                            class="primary-btn">{{ constants.SUBSCRIBE_BUTTON }}</button>
                                    </div>
                                    <span class="error-text" v-if="titleError && !email">{{
                                        constants.THIS_FIELD_IS_REQUIRED
                                    }}</span>
                                    <span class="error-text" v-if="!(titleError && !email)">{{ emailError }}</span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </section>

        <FooterComponent></FooterComponent>


    </div>
    <AboutMeComponent></AboutMeComponent>
    <ContactUsComponent></ContactUsComponent>
    <AppointmentComponent></AppointmentComponent>
    <BenefitsOfSubComponent></BenefitsOfSubComponent>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import axios from 'axios';

import 'slick-carousel';
import $ from 'jquery';
import { useStore } from 'vuex';

import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import AboutMeComponent from "@/components/AboutMeComponent.vue";
import ContactUsComponent from "@/components/ContactUsComponent.vue";
import AppointmentComponent from '@/components/AppointmentComponent.vue';
import BenefitsOfSubComponent from '@/components/BenefitsOfSubComponent.vue';
import CdnImageLoader from "@/components/cdnImageLoaderComponent.vue";
import { environment } from '@/config/environment';


import customJSFunction from "@/assets/js/custom.js";
import constants from '@/utils/en-US-User.json';

import { API_BASE_URL } from '@/config/api.config';


interface AboutUs {
    [key: string]: any;
    title: string;
    brief_description: string;
    description: string;
    social_media: Array<{ name: string; url: string }>;
    images: Array<{ id: number, image: string }>;
}

interface Service {
    id: number;
    title: string;
    short_description: string;
    description: string;
    image: File | string;
    editing: boolean;
    original?: Service;
    [key: string]: any;
}

interface ServiceMain {
    id: number;
    title: string;
    sub_content: string;
    services: Service[];
}

interface Blog {
    id: number;
    title: string;
    short_description: string;
    description: string;
    BlogImage: string;
    publishedDate: string;
}

export default defineComponent({
    data() {
        return {
            constants: constants,
            BlogData: [] as Blog[],
            slides: [] as { id: number, slide_title: string, slide_image: string }[],
            email: '',
            titleError: false,
            emailError: '',
            nextClicked: false,
            aboutUsData: {
                id: 1,
                title: '',
                brief_description: '',
                description: '',
                social_media: [],
                images: [],
            } as AboutUs,
            benefits: {
                id: '',
                title: '',
                description: ''
            }
        };
    },
    created() {
        this.fetchSlides();
        this.fetchAboutMeData();
        this.fetchBenefits();
        this.fetchBlogs()

    },
    // beforeRouteEnter(to, from, next) {
    //     if (from.path === '/services' || from.path === '/blog' || from.path === '/pay') {
    //         window.location.reload();
    //     } else {
    //         next();
    //     }
    // },
    mounted() {
        customJSFunction();
    },
    components: {
        HeaderComponent,
        FooterComponent,
        AboutMeComponent,
        ContactUsComponent,
        AppointmentComponent,
        BenefitsOfSubComponent,
        CdnImageLoader
    },
    methods: {
        getCdnImageAddress(src: string): string {
            if (!src) return '';
            let cdnUrl: string = environment.imageCdn.getHost;
            cdnUrl = cdnUrl.concat('w_1400,h_700/');
            return cdnUrl + encodeURIComponent(src);
        },

        trimText(text: string, length: number) {
            if (text.length > length) {
                return text.substring(0, length) + '...';
            }
            return text;
        },
        saveId(id: number) {
            localStorage.setItem('blogId', id.toString());
        },
        async fetchBlogs() {
            try {
                const response = await axios.get(`${API_BASE_URL}/blogs/`);
                this.BlogData = response.data.filter((blog: { status: number; }) => blog.status === 1)

            } catch (error) {
                console.error(error);
            }
        },
        async fetchBenefits() {
            try {
                const response = await axios.get(`${API_BASE_URL}/benefits/`);
                if (response.data && response.data.length > 0) {
                    this.benefits = response.data[0];
                }
                else {
                    this.benefits = {
                        id: '',
                        title: '',
                        description: ''
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        async fetchSlides() {
            try {
                const response = await axios.get(`${API_BASE_URL}/homepage_hero/`);
                if (response.data !== null) {
                    this.slides = response.data;
                    this.slides = response.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id);

                }
            } catch (error) {
                console.error(error);
            }
        },
        async fetchAboutMeData() {
            try {
                const response = await axios.get(`${API_BASE_URL}/aboutus/`);
                this.aboutUsData = response.data[0];

            } catch (error) {
                console.error(error);
            }
        },

        initializeHeroSlider() {
            if ($('.hero-slider').length) {
                $('.hero-slider').slick({
                    dots: true,
                    arrows: true,
                    infinite: false,
                    fade: true,
                    speed: 600,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                });
            }
        },

        initializeBlogSlider() {
            if ($('.blog-slider').length) {
                $(".blog-slider").slick({
                    dots: true,
                    arrows: true,
                    infinite: false,
                    fade: false,
                    speed: 600,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                });
            }
        },

        initializeServicesSlider() {
            if ($('.service-slider').length) {
                $(".service-slider").slick({
                    dots: false,
                    arrows: true,
                    infinite: false,
                    fade: false,
                    speed: 600,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    responsive: [

                        {
                            breakpoint: 1100,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 567,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                        // You can unslick at a given breakpoint now by adding:
                        // settings: "unslick"
                        // instead of a settings object
                    ]
                });
            }
        },



        initializeSliders() {
            this.initializeHeroSlider();
            this.initializeBlogSlider();
            this.initializeServicesSlider();
        },
        validateEmail(nextClicked: boolean) {
            if (nextClicked) {
                if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
                    this.emailError = constants.VALID_EMAIL;
                } else {
                    this.emailError = '';
                }
            }
        },
        async subscribe() {
            try {
                this.nextClicked = true;
                this.validateEmail(this.nextClicked);
                // const response = await axios.post(`${API_BASE_URL}/newsSubscribe/`, {
                //     Email: this.email,
                // });
                if (!this.email) {
                    this.titleError = true;
                    return;
                } else {
                    this.titleError = false;
                }
                if (this.emailError !== '') {
                    return;
                }

                this.$router.push({ name: 'PayView', params: { email: this.email } });
                this.email = '';
                this.titleError = false;
                this.emailError = '';
                this.nextClicked = false;
            } catch (error) {
                console.error(error);
            }
        },
    },
    watch: {
        slides: {
            handler(newVal) {
                if (newVal.length > 0) {
                    this.$nextTick(() => {
                        this.initializeHeroSlider();
                    });
                }
            },
            deep: true
        },
        BlogData: {
            handler(newVal) {
                if (newVal.length > 0) {
                    this.$nextTick(() => {
                        this.initializeBlogSlider();
                    });
                }
            },
            deep: true
        },
        services: {
            handler(newVal) {
                if (newVal.length > 0) {
                    this.$nextTick(() => {
                        this.initializeServicesSlider();
                    });
                }
            },
            deep: true
        },

    },
    setup() {
        const store = useStore();
        const isActive = computed(() => store.state.isActive);
        const activeIcon = ref('home');
        const serviceMain = ref<ServiceMain | null>(null);
        const services = ref<Service[]>([]);

        const fetchServiceMain = async () => {
            const response = await axios.get(`${API_BASE_URL}/service_main/`);
            serviceMain.value = response.data[0];
        };

        const fetchServices = async () => {
            const response = await axios.get(`${API_BASE_URL}/services/`);
            services.value = response.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id);

        };

        onMounted(() => {
            fetchServiceMain();
            fetchServices();
        });
        return {
            activeIcon,
            serviceMain,
            services,
            fetchServiceMain,
            fetchServices,
            isActive,
        };
    },
});
</script>
